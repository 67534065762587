import * as preact from "preact";
import { VNode } from "preact";

import * as css from "@lib/css";
import * as rpc from "@lib/rpc";
import * as cache from "@lib/cache";
import * as refs from "@lib/refs";
import * as geom from "@lib/geom";

let global = window as any;

export let dragging: any = null; // for user code to set to anything!
export function setDragging(d: any) {
    dragging = d;
}

let _debugVars: Record<string, any> = {};

export function debugVar(data: any) {
    Object.assign(_debugVars, data);
}

let frameMessages = new Map<string, any>()
export function postFrameMessage(key: string, value: any) {
    frameMessages.set(key, value)
}
export function consumeFrameMessage<T = any>(key: string): T | null {
    let value = frameMessages.get(key)
    frameMessages.delete(key)
    return value
}

export let rootDiv = document.body;

export let event: Event | null = null; // stores current event
function _redraw(): number {
    // returns the time it took to render in ms
    if (rootDiv === null) {
        return 0;
    }
    const t1 = performance.now();
    const vdom = renderPageAndModals(gRootPage, gModalStack);
    preact.render(vdom, rootDiv);
    event = null;
    _debugVars = {};
    frameMessages.clear()

    const t2 = performance.now();
    const duration = t2 - t1;
    // if (duration > 5) {
    //     console.info(`redraw ${(duration).toFixed(2)}ms`);
    // }
    return duration;
}

// global.redraw = redraw;

let _redrawSchedule = 0;
function _animationFrameRedraw() {
    _redrawSchedule = 0;
    _redraw();
}

// schedules redraw as soon as possible
//
// this is so that the caller can do a bunch of stuff and then have
// the redraw called only after they're done with everything or when
// they have to yield for an async call
//
// Safe to schedule multiple times per frame; only the first time will schedule
export function scheduleRedraw() {
    if (_redrawSchedule === 0) {
        _redrawSchedule = requestAnimationFrame(_animationFrameRedraw);
    }
}

let _deferredRedrawScheduled = 0;
let _deferredRedrawDuration = 30;
function _deferredRedraw() {
    _deferredRedrawScheduled = 0;
    let duration = _redraw();
    _deferredRedrawDuration = Math.max(30, Math.round(duration * 4));
}

export function deferRedraw() {
    if (_deferredRedrawScheduled == 0) {
        _deferredRedrawScheduled = setTimeout(
            _deferredRedraw,
            _deferredRedrawDuration,
        );
    }
}

// @ts-ignore
window.scheduleRedraw = scheduleRedraw;
// @ts-ignore
window.refs = refs;

export function debugVarsPanel(): VNode {
    return preact.h("div", { class: _clsDebugVars }, [
        _showDebugEntries(_debugVars),
    ]);
}

function _showDebugEntries(data: any, prefix = ""): VNode {
    return preact.h(
        preact.Fragment,
        {},
        Object.entries(data).map(([key, value]) => {
            if (typeof value === "object") {
                return _showDebugEntries(value, prefix + key + ".");
            } else {
                return preact.h("div", {}, [`${prefix + key}: ${value}`]);
            }
        }),
    );
}

const _clsDebugVars = css.cls("debug-vars", {
    position: "fixed",
    zIndex: "100000000",
    top: "0px",
    left: "0px",
    width: "content-fit",
    height: "content-fit",
    padding: "2px 4px",
    borderBottomRightRadius: "4px",
    fontFamily: "monospace",
    background: "hsla(0, 0%, 0%, 0.6)",
    color: "white",
    textShadow: "0px 0px 2px black",
    fontSize: "14px",
    ":empty": {
        display: "none",
    },
});

function nodeCustomEvent(node: Node, eventName: string, options?: object) {
    const event = new CustomEvent(eventName, options);
    node.dispatchEvent(event);
    // console.log(eventName, node)
}

function recursivelyDispatchCreateEvent(node: Node) {
    if (node instanceof Element && node.hasAttribute("listen-create")) {
        nodeCustomEvent(node, "create");
    }
    node.childNodes.forEach(recursivelyDispatchCreateEvent);
}

const observer = new MutationObserver(function (
    mutationsList: MutationRecord[],
    observer: MutationObserver,
) {
    const t1 = performance.now();
    for (const mutation of mutationsList) {
        if (mutation.target instanceof Element) {
            if (mutation.target.hasAttribute("listen-mutate")) {
                nodeCustomEvent(mutation.target, "mutate", { bubbles: true });
            }
        }
        mutation.addedNodes.forEach(recursivelyDispatchCreateEvent);
        // mutation.removedNodes.forEach(node => nodeCustomEvent(node, 'destroy'));
    }
    const dur = performance.now() - t1;
    if (dur > 1) {
        console.log(`mutation dispatch ${dur.toFixed(2)}ms`);
    }
});
observer.observe(rootDiv, {
    subtree: true,
    childList: true,
    characterData: true,
    attributes: true,
});

let blockTouchMoveRequested = false
export function blockTouchMove(b: boolean) {
    blockTouchMoveRequested = b
}

export function captureEvent(e: Event) {
    if (e instanceof KeyboardEvent) {
        if (e.isComposing) {
            return;
        }
    }

    if (e instanceof MouseEvent) {
        captureMouseLocation(e)
    }

    // Safari-desktop does not even define TouchEvent so we have to check first
    if (window['TouchEvent'] && e instanceof TouchEvent) {
        captureTouchLocation(e)
        if (blockTouchMoveRequested) {
            e.preventDefault()
        }
    }
    event = e; // make it available to all renderers!
    scheduleRedraw();
}

export let mouse = geom.zeroPoint();
export let touches: geom.Point[] = []
export let touchIds: number[] = [] // the value is the id, the index is the index of the touch location in touches

export function isTouching() {
    return touches.length > 0
}

// window.addEventListener("click", captureEvent)
window.addEventListener("touch", captureEvent);
window.addEventListener("touchstart", captureEvent);
window.addEventListener("touchend", captureEvent);
window.addEventListener("mousedown", captureEvent);
window.addEventListener("mouseup", captureEvent);
document.addEventListener("input", captureEvent);
document.addEventListener("keydown", captureEvent);

window.addEventListener("mousemove", captureEvent, { passive: false });
window.addEventListener("touchmove", captureEvent, { passive: false });

function hasMouseDevice() {
    // js wtf
    return matchMedia('(pointer:fine)').matches
}

function isActuallyTouchEvent(e: MouseEvent) {
    return !hasMouseDevice() || (e as any).sourceCapabilities?.firesTouchEvents === true
}

function captureMouseLocation(e: MouseEvent) {
    if (isActuallyTouchEvent(e)) return

    mouse.x = e.clientX;
    mouse.y = e.clientY;
}

function captureTouchLocation(e: TouchEvent) {
    touches = []
    touchIds = []

    for (let [index, touch] of Array.from(e.touches).entries()) {
        touches[index] = { x: touch.clientX, y: touch.clientY }
        touchIds[index] = touch.identifier
    }
    deferRedraw();
}

// helper
export function touchIdByIndex(idx: number): number {
    return touchIds[idx]
}

// helper
export function touchById(touchId: number): geom.Point | null {
    for (let [index, id] of touchIds.entries()) {
        if (id === touchId) {
            return touches[index]
        }
    }
    return null
}

function passiveCapture(_event: Event) {
    deferRedraw();
}

document.addEventListener("scroll", passiveCapture, { passive: true });
window.addEventListener("resize", passiveCapture, { passive: true });

export function isUnderMouse(el: Element | null | undefined, cursor = mouse): boolean {
    if (!el) {
        return false;
    }
    let underMouse = document.elementFromPoint(cursor.x, cursor.y);
    return el === underMouse || el.contains(underMouse);
}

export function elementRect(element: HTMLElement | null): geom.Rect {
    if (element === null) {
        return geom.zeroRect();
    } else {
        let r0 = element.getBoundingClientRect();
        return {
            x: r0.x,
            y: r0.y,
            width: r0.width,
            height: r0.height,
        };
    }
}

export type ClickLocation = "inside" | "outside" | "na";

export function clickLocationRelativeTo(el: HTMLElement | null) {
    if (
        el &&
        event &&
        event.target instanceof HTMLElement &&
        event.type === "mousedown"
    ) {
        if (el.contains(event.target)) {
            return "inside";
        } else {
            return "outside";
        }
    } else {
        return "na";
    }
}

export function clickOutside(...elements: (HTMLElement|null)[]) {
    if (
        event &&
        event.target instanceof HTMLElement &&
        event.type === "mousedown"
    ) {
        for (let el of elements) {
            if (el && el.contains(event.target)) {
                return false
            }
        }
        return true;
    } else {
        // there's no click ..
        return false;
    }
}

export function keydownOn(el: HTMLElement | null): string {
    if (event && event.type === "keydown" && event.target === el) {
        return (event as KeyboardEvent).key;
    } else {
        return "";
    }
}

export function eventTargetId(type: string, targetId: string): boolean {
    return Boolean(event && event.type === type && event.target === document.getElementById(targetId))
}

export function inputEventOn(el: HTMLElement | null) {
    return el && event && event.type === "input" && event.target === el;
}

export function getEventTarget(): HTMLElement | null {
    if (event && event.target instanceof HTMLElement) {
        return event.target;
    } else {
        return null;
    }
}

// from https://web.dev/articles/canvas-hidipi
export function getContext2D(canvas: HTMLCanvasElement): CanvasRenderingContext2D {
    // Get the device pixel ratio, falling back to 1.
    var dpr = window.devicePixelRatio || 1;
    // Get the size of the canvas in CSS pixels.
    var rect = canvas.getBoundingClientRect();
    // Give the canvas pixel dimensions of their CSS
    // size * the device pixel ratio.
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    var ctx = canvas.getContext('2d')!;
    ctx.clearRect(0, 0, rect.width, rect.height);
    // Scale all drawing operations by the dpr, so you
    // don't have to worry about the difference.
    ctx.scale(dpr, dpr);
    return ctx;
}

export function getWindowSize(): geom.Size {
    return {
        width: Math.min(window.outerWidth, window.innerWidth),
        height: Math.min(window.outerHeight, window.innerHeight),
    };
}

export function getScreenSize(): geom.Size {
    return {
        width: screen.availWidth,
        height: screen.availHeight,
    }
}

/*
    getImageSize fetches the image if its size is not already known.

    It's a good idea to call it as soon as possible even if the result is not going to be needed yet.
*/
const _imageSizes = new Map<string, geom.Size>();
const _waitingImages = new Set<string>();
export function getImageSize(url: string): geom.Size {
    const storedSize = _imageSizes.get(url);
    if (storedSize !== undefined) {
        return storedSize;
    }
    // we cannot find, lets fetch it
    // but if it's already being fetched, no need to fetch it again.
    if (!_waitingImages.has(url)) {
        _waitingImages.add(url);
        const img = new Image();
        img.onload = () => {
            _waitingImages.delete(url);
            _imageSizes.set(url, {
                width: img.naturalWidth,
                height: img.naturalHeight,
            });
            scheduleRedraw();
            // console.log("downloaded", url)
        };
        img.src = url;
    }
    return { width: 0, height: 0 };
}

export type ResolverFn<R> = (v: R) => void;
export type ModalViewFn<T = any, R = any> = (
    vm: T,
    resolve: ResolverFn<R>,
) => VNode;

interface RootPage<Data = any> {
    route: string;
    prefix: string;
    data: Data;
    view: (route: string, prefix: string, data: Data) => VNode;
}

let gRootPage: RootPage | null = null;

export function setRootPage(r: RootPage) {
    gRootPage = r;
}

global.getRoot = () => gRootPage;

export function getPageData(): unknown {
    return gRootPage?.data;
}

const clsModalBackdrop = css.cls("modal_backdrop", {
    position: "fixed",
    zIndex: "100000",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "hsla(0, 0%, 0%, 0.5)",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});

function modalContainer(
    zIndex: number,
    content: VNode,
    resolver: Function,
    clickOutsideValue?: any,
): VNode {
    const id = "modal_" + zIndex;
    // const style = `z-index: ${zIndex}`; // FIXME is this needed?
    const onMouseDown = (event: MouseEvent) => {
        // ignore events caused by event propagation
        // console.log("target", event.target, "currentTarget", event.currentTarget);
        if (event.target !== event.currentTarget) {
            return;
        }

        if (clickOutsideValue !== undefined) {
            resolver(clickOutsideValue);
            scheduleRedraw();
        }
    };
    return preact.h("div", { id: id, class: clsModalBackdrop, onMouseDown }, [
        content,
    ]);
}

function renderPageAndModals(
    rootPage: RootPage | null,
    modalStack: ModalEntry[],
): VNode {
    let main: VNode;
    let modals: VNode[] = [];
    if (rootPage !== null) {
        main = rootPage.view(rootPage.route, rootPage.prefix, rootPage.data);
    } else {
        main = preact.h("div", {});
    }
    const base_z_index = 10000;
    for (const [index, modalItem] of modalStack.entries()) {
        const modalContent = modalItem.view(modalItem.vm, modalItem.resolve);
        modals.push(
            modalContainer(
                base_z_index + 1 + index,
                modalContent,
                modalItem.resolve,
                modalItem.clickOutsideValue,
            ),
        );
    }
    return preact.h(preact.Fragment, {}, [main, ...modals]);
}

export interface RouteEntry<Data = any> {
    prefix: string;
    fetch: (route: string, prefix: string) => Promise<rpc.Response<Data>>;
    view: (route: string, prefix: string, data: Data) => VNode;
}

export function routeEntry<Data>(
    prefix: string,
    fetch: RouteEntry<Data>["fetch"],
    view: RouteEntry<Data>["view"],
): RouteEntry<Data> {
    return { prefix, fetch, view };
}

let errorView: (route: string, prefix: string, error: string) => VNode;

errorView = (_r, _p, e: string) => preact.h("h1", { children: e }); // default dummy error view

export function setErrorView(
    pErrorView: (r: string, p: string, e: string) => VNode,
) {
    errorView = pErrorView;
}

export function setRoute(route: string) {
    location.href = route;
}

export function replaceRoute(route: string) {
    history.replaceState(null, "", document.location.pathname + "#" + route);
    onHashChange();
}

export function getRoute(): string {
    let hash = location.hash;
    if (hash.startsWith("#")) {
        hash = hash.substring(1);
    }
    hash = decodeURI(hash);
    return hash;
}

export type ParsedRoute = {
    pathname: string;
    searchParams: URLSearchParams;
};

export function parseRoute(route: string): ParsedRoute {
    let url = new URL(route, location.origin);
    return {
        pathname: url.pathname,
        searchParams: url.searchParams,
    };
}

export function getRouteParsed(): ParsedRoute {
    return parseRoute(getRoute());
}

export function href(route: string) {
    return "#" + route;
}

let gRoutes: RouteEntry[] = [];

interface ModalEntry<T = any, R = any> {
    vm: T;
    resolve: ResolverFn<R>;
    view: ModalViewFn<T, R>;
    clickOutsideValue?: R;
}

let gModalStack: ModalEntry[] = [];

export function openModal<T = any, R = any>(
    vm: T,
    view: ModalViewFn<T, R>,
    clickOutsideValue?: R,
): Promise<R> {
    const result = new Promise<R>((resolve) => {
        const entryIndex = gModalStack.length;
        const resolveAndClose = (result: R): void => {
            resolve(result);
            gModalStack.splice(entryIndex, 1);
            scheduleRedraw();
        };
        const entry: ModalEntry<T, R> = {
            vm,
            resolve: resolveAndClose,
            view,
            clickOutsideValue,
        };
        gModalStack.push(entry);
        // Note: resolve will be called by the view
    });
    scheduleRedraw();
    return result;
}

export function closeTopModal() {
    let topIndex = gModalStack.length-1
    gModalStack.splice(topIndex, 1);
    scheduleRedraw();
}

let cleanupFunctions: Function[] = [];

// register a cleanup function to be called when a page changes
// for modules that can't be imported here due circularity
export function registerCleanupFunction(fn: Function) {
    cleanupFunctions.push(fn);
}

// state to helps us set/restore scroll position across navigations
let preNavStateId = 0;
let postNavStateId = 0;

function onHashChange() {
    const routes = gRoutes;
    let route = getRoute();
    for (const entry of routes) {
        if (route.startsWith(entry.prefix)) {
            // console.log('found matching entry:', entry);
            scheduleRedraw();

            storePageScroll(preNavStateId, window.scrollY);

            entry.fetch(route, entry.prefix).then(([data, error]) => {
                // reset page data ...
                cache.clear();
                refs.clear();
                gModalStack.splice(0) // remove all items (reset slice)
                for (let fn of cleanupFunctions) {
                    fn();
                }

                if (data) {
                    setRootPage({
                        route,
                        prefix: entry.prefix,
                        data: data,
                        view: entry.view,
                    });
                } else {
                    setRootPage({
                        route,
                        prefix: entry.prefix,
                        data: error,
                        view: errorView,
                    });
                }

                let desiredYScroll = retrievePageScroll(postNavStateId);

                scheduleRedraw();
                requestAnimationFrame(() => {
                    window.scrollTo(0, desiredYScroll);
                });
            });
            break;
        }
    }
}

let _scrolls = new Map<number, number>();
function storePageScroll(stateId: number, value: number) {
    _scrolls.set(stateId, value);
}

function retrievePageScroll(stateId: number) {
    return _scrolls.get(stateId) ?? 0;
}

function onPopState(event: PopStateEvent) {
    if (event.state) {
        preNavStateId = postNavStateId;
        postNavStateId = event.state.ts;
    } else {
        // we're going to a new page now
        preNavStateId = postNavStateId;
        postNavStateId = Date.now();

        // replace state because the system has already inserted a history entry with a null state
        history.replaceState({ ts: postNavStateId }, "");
    }
}

export function initRoutes(routes: RouteEntry[]) {
    if (location.hash === "") {
        location.hash = "/";
    }
    gRoutes = routes;
    window.addEventListener("hashchange", onHashChange);
    window.addEventListener("popstate", onPopState);

    // initial state; we use 0 becuase that's the default value for preNavStateId
    history.replaceState({ ts: 0 }, "");
    history.scrollRestoration = "manual";
    onHashChange();
}

interface Queryable {
    [key: string]: number | string;
}

export function queryString(params: Queryable): string {
    const elements: string[] = [];
    for (const key in params) {
        const value = String(params[key]);
        elements.push(key + "=" + encodeURIComponent(value));
    }
    return elements.join("&");
}

export async function mswait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function waitUntil(t: number) {
    let now = Date.now();
    let dur = t - now;
    if (dur <= 0) {
        return Promise.resolve();
    }
    return new Promise((resolve) => setTimeout(resolve, dur));
}

export function localStorageValue<T>(key: string, fallback: T): T {
    let raw = localStorage.getItem(key);
    if (raw === null) {
        return fallback;
    }
    try {
        return JSON.parse(raw);
    } catch {
        return fallback;
    }
}

export function focusAfterFrame<T extends HTMLElement>(
    ref: refs.Ref<T | null>,
) {
    setTimeout(() => {
        let el = refs.get(ref);
        if (el) {
            el.focus();
            scheduleRedraw();
        }
    });
}

// ====== i18n ======

export type LANG = string;

export const EN: LANG = "en";
export const AR: LANG = "ar";
export const JA: LANG = "ja";

export let lang = EN;

export function setLang(v: string) {
    lang = v;
}

export type LocalizedTextMap = [LANG, string][];

export function selectLocalizedTextByLang(map: LocalizedTextMap, lang: string) {
    for (let [lang0, text0] of map) {
        if (lang === lang0) {
            return text0;
        }
    }
    return map[0][1];
}

export function selectLocalizedText(map: LocalizedTextMap): string {
    return selectLocalizedTextByLang(map, lang);
}
