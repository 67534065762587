import * as preact from "preact";
import { VNode } from "preact";

import * as refs from "@lib/refs";
import * as rpc from "@lib/rpc";
import * as core from "@lib/core";
import * as cache from "@lib/cache";
import * as ui from "@lib/ui";
import * as geom from "@lib/geom";
import * as css from "@lib/css";
import * as events from "@lib/events";
import * as utils from "@lib/utils";

import * as auth from "@auth/auth";
import * as auth_forms from "@auth/forms";

import * as server from "./server";

import * as landing from "./landing";
import * as layout from "./layout";

class ImagePage {
    imageContainer: HTMLElement | null = null;
    pins: HTMLElement | null = null;
    pinnedUserNotes: PinnedUserNote[] = [];

    constructor(public data: server.LoadImageResponse) {}
}

export async function makeImagePage(route: string, prefix: string): Promise<rpc.Response<ImagePage>> {
    const imageName = utils.removePrefix(route, prefix);
    const [data, err] = await server.LoadImage({ ImageName: imageName });
    if (data) {
        return [new ImagePage(data), ""];
    } else {
        return [null, err];
    }
}

export function viewImagePage(_route: string, _prefix: string, page: ImagePage): VNode {
    (window as any).page = page;
    let cfg = _ImagePageViewConfig(page);
    let hoveredWordList = _DoWordHovering(page, cfg);
    // core.debugVar({zoomFactor: cfg.zoomFactor.toFixed(2)})

    let imagePlacement: geom.Placement = {
        width: cfg.imageRect.width,
        height: cfg.imageRect.height,
        left: cfg.imageRect.x,
    };

    let canvas = document.getElementById("annotations_canvas");
    if (canvas instanceof HTMLCanvasElement) {
        let ctx = core.getContext2D(canvas)

        function moveTo(pt: server.Point) {
            ctx.moveTo(pt.x * cfg.zoomFactor, pt.y * cfg.zoomFactor);
        }

        function lineTo(pt: server.Point) {
            ctx.lineTo(pt.x * cfg.zoomFactor, pt.y * cfg.zoomFactor);
        }

        function arc(
            center: server.Point,
            radius: number,
            startAngle: number,
            endAngle: number,
            counterclockwise: boolean = false,
        ) {
            ctx.arc(
                center.x * cfg.zoomFactor,
                center.y * cfg.zoomFactor,
                radius,
                startAngle,
                endAngle,
                counterclockwise,
            );
        }

        function circle(center: server.Point, radius: number) {
            arc(center, radius, 0, Math.PI * 2);
        }

        function fillText(t: string, pt: server.Point) {
            ctx.fillText(t, pt.x * cfg.zoomFactor, pt.y * cfg.zoomFactor);
        }

        let notes = page.pinnedUserNotes

        for (let item of notes) {
            for (let line of item.pin.lines) {
                // core.debugVar({pt0, pt1})

                ctx.strokeStyle = item.pin.color;
                ctx.lineWidth = item.pin.thickness;
                ctx.lineCap = "round";
                ctx.beginPath();
                moveTo(line[0]);
                lineTo(line[1]);
                ctx.stroke();
            }
        }

        for (let word of hoveredWordList) {
            let color = hilightColors[page.pinnedUserNotes.length % hilightColors.length];

            for (let rect of word.BBoxes) {
                ctx.beginPath();
                moveTo(rect.Points[0]);
                for (let i = 1; i <= 3; i++) {
                    lineTo(rect.Points[i]);
                }
                ctx.closePath();
                // ctx.fillStyle = color + "40";
                // ctx.fill();
                ctx.strokeStyle = color + "40";
                ctx.lineWidth = 2
                ctx.stroke()
            }
        }


        if (_isTouching) {
            // draw cursor
            let cursor = _CursorRelativeToImage(page, cfg, _cursor)

            const drawCircle = true
            const drawCrosshair = true
            let radius = 100 * cfg.zoomFactor
            let crosshair = 160

            // core.debugVar({crosshair, radius: radius.toFixed(2)})

            if (drawCircle) {
                // draw circle
                ctx.beginPath();
                circle(cursor, radius)
                ctx.closePath()

                ctx.fillStyle = "#1122ee55"
                ctx.fill()

                ctx.strokeStyle = "RoyalBlue"
                ctx.lineWidth = 2
                ctx.stroke()
            }


            if (drawCrosshair) {
                // draw cross hair
                ctx.lineWidth = 1
                ctx.strokeStyle = "Green"
                ctx.beginPath()
                moveTo(geom.pointAdd(cursor, {x: -crosshair, y:0}))
                lineTo(geom.pointAdd(cursor, {x: crosshair, y:0}))
                ctx.closePath()
                ctx.stroke()

                ctx.beginPath()
                moveTo(geom.pointAdd(cursor, {y: -crosshair, x:0}))
                lineTo(geom.pointAdd(cursor, {y: crosshair, x:0}))
                ctx.closePath()
                ctx.stroke()
            }
        }
    }

    const containerHeight = visualViewport!.height - layout.TOPBAR_HEIGHT
    const pinsHeight = core.elementRect(page.pins).height
    const imgHeight = cfg.mobileMode ? containerHeight - pinsHeight : containerHeight

    return layout.page(
        <>
            <div style={{ height: containerHeight, overflow: "hidden", }}>
                <div class={clsImageContainer} style={{ height: imgHeight }} {...events.elementRefAttrs(refs.ref(page, "imageContainer"))} >
                    <img src={page.data.ImageURL} style={{ ...imagePlacement }} />
                    <canvas
                        id="annotations_canvas"
                        class={clsAnnotationsContainer}
                        width={imagePlacement.width}
                        height={imagePlacement.height}
                        style={{ ...imagePlacement }}
                    />
                </div>
                {drawPinnedNotes(page, cfg)}
            </div>
            <div class={clsFontPrefetcher}>
            {hoveredWordList.map((word, idx) =>
                <div key={idx}>
                    <span class="jp">{word.Reading} ({word.Text})</span>
                    {page.data.Lookup[word.Text].Senses.map((m, idx) => <div key={idx} style={{ fontFamily: enFontFamily }}>
                        {m.Readings.join(" ")}
                        <br />
                        {m.Writings.join(" ")}
                        <br />
                        {m.Glossary.join(', ')}
                    </div>)}
                </div>)}
            </div>
        </>,
    );
}

const clsImageContainer = css.cls("image-cont", {
    position: "relative",
    margin: "0 auto",
    overflowY: "auto",
    overflowX: "hidden",

    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "user-select": "none",

    "touch-action": "pan-x pan-y",

    img: {
        position: "absolute",
        // zIndex: 1,
    },
});

const clsAnnotationsContainer = css.cls("annotations-cont", {
    position: "absolute",
    // zIndex: 10, // above the image
});

function simplifiedBBox(rect: server.Rect): geom.Rect {
    let minX = 1000000000;
    let minY = 1000000000;
    let maxX = -1000000000;
    let maxY = -1000000000;
    for (let point of rect.Points) {
        minX = Math.min(minX, point.x)
        maxX = Math.max(maxX, point.x)
        minY = Math.min(minY, point.y)
        maxY = Math.max(maxY, point.y)
    }
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    }
}

const NOTE_FONT_SIZE = 18;

type NotePin = {
    color: string;
    lines: geom.Line[];
    thickness: number;
}

type UserNote = {
    annotation: server.TextAnnotation; // the word!
    wordId: number;
    selected: string[]; // a list of selected items from the glossaries
    selectedKeys: string[];
    note: string;
    explicitPin: boolean;
    pin: NotePin | null
};

type PinnedUserNote = UserNote & {
    pin: NotePin // only difference is the pin is mandetory here
}

function _makeUserNote(annotation: server.TextAnnotation, wordId: number): UserNote {
    return {
        annotation,
        wordId,
        selected: [],
        selectedKeys: [],
        note: "",
        explicitPin: false,
        pin: null,
    }
}

function useUserNote(word: server.TextAnnotation, wordId: number): UserNote {
    return cache.get([cache.byId(word), wordId], _makeUserNote, word, wordId)
}

let hilightColors = ["#bb0000", "#00bb00", "#0000bb", "#bb00bb", "#ff9900", "#0099ff"];

function _CursorRelativeToImage(page: ImagePage, cfg: PageViewConfig, cursor: geom.Point): geom.Point {
    cursor = { ...cursor } // copy
    cursor.y += page.imageContainer!.scrollTop
    cursor.x -= cfg.imageRect.x;
    cursor.y -= cfg.imageRect.y;
    cursor.x /= cfg.zoomFactor;
    cursor.y /= cfg.zoomFactor;
    return cursor
}

// mouse: the mouse coordinates adjusted to be relative to the image container and scaled appropriately
function _CollectWordListUnderCursor(page: ImagePage, cfg: PageViewConfig, cursor: geom.Point): server.TextAnnotation[] {
    // mouse relative to the image
    cursor = _CursorRelativeToImage(page, cfg, cursor)

    function pointInRect(point: geom.Point, rect: geom.Rect) {
        return (
            point.x >= rect.x &&
            point.x <= rect.x + rect.width &&
            point.y >= rect.y &&
            point.y <= rect.y + rect.height
        );
    }

    let words: server.TextAnnotation[] = [];
    for (let word of page.data.Words) {
        let rects = word.BBoxes;
        for (let rect of rects) {
            // require it to be under mouse!
            let rectBox = simplifiedBBox(rect)
            if (pointInRect(cursor, rectBox)) {
                // add the compound word after its children
                words.push(word);
                break; // no need to check rest of boxes
            }
        }
    }

    let siblings: server.TextAnnotation[] = []
    if (words.length > 0) {
        // collect all the component children if not already in the list
        nextChild:
        for (let child of page.data.Words) {
            if (words.includes(child)) {
                continue nextChild
            }
            for (let childRect of child.BBoxes) {
                for (let word of words) {
                    for (let rect of word.BBoxes) {
                        let enclosed = true
                        let box = simplifiedBBox(rect)
                        for (let point of childRect.Points) {
                            if (!pointInRect(point, box)) {
                                enclosed = false
                                break
                            }
                        }
                        if (enclosed) {
                            siblings.push(child)
                            continue nextChild
                        }
                    }
                }
            }
        }
    }
    words.push(...siblings)

    return words;
}

const MAX_IMAGE_WIDTH = 700;
const MIN_MARGIN_WIDTH = 120; // threshold for switching to mobile view

function _ImagePageViewConfig(page: ImagePage): PageViewConfig {
    let winsize = core.getWindowSize();

    let containerRect = core.elementRect(page.imageContainer);

    let containerSize: geom.Size = {
        width: winsize.width,
        height: winsize.height - containerRect.y,
    };

    const maxImageWidth = Math.min(MAX_IMAGE_WIDTH, winsize.width)

    let imageSizeContraints: geom.SizeConstraints = {
        maxWidth: maxImageWidth,
    };

    let imageSize0 = core.getImageSize(page.data.ImageURL);
    let imageSize = geom.constrainedSize(imageSize0, imageSizeContraints);

    let zoomFactor = 1;
    if (imageSize0.width > 0) {
        zoomFactor = imageSize.width / imageSize0.width;
    }

    let sideMargin = (containerSize.width - imageSize.width) / 2;

    let imageRect: geom.Rect = {
        ...imageSize,
        x: sideMargin,
        y: containerRect.y,
    };

    // set max height after setting up the image rect
    // this keeps the image rect centered but adjusts
    // the max width of the pinned notes
    sideMargin = Math.min(sideMargin, 200);

    let cfg: PageViewConfig = {
        sideMargin,
        containerSize,
        imageRect,
        zoomFactor,
        mobileMode: sideMargin < MIN_MARGIN_WIDTH
    };

    return cfg;
}

let _cursor = geom.zeroPoint()

// owned by _DoWordHovering
let _mouseDownTarget: server.TextAnnotation | null = null
let _isTouching = false

// returns the word to be hilighted as the current hovered word
// applies word list panel logic, and mutates page.panel accordingly
function _DoWordHovering(page: ImagePage, cfg: PageViewConfig): server.TextAnnotation[] {
    if (!page.imageContainer) {
        return []
    }

    let hoveredWordList: server.TextAnnotation[] = [];

    let hasMouse = matchMedia('(pointer:fine)').matches // wtf
    let gesture = _DoGestureRecognition(page.imageContainer)
    _isTouching = Boolean(gesture && gesture.inspectMode)
    if (gesture && gesture.inspectMode) {
        let verticalOffset = -60

        let touch0 = core.touchById(gesture.tid0)
        if (touch0) {
            _cursor = geom.pointAdd(touch0, {x: 0, y: verticalOffset})
        }
    }

    if (hasMouse && !_isTouching) {
        _cursor =  { ...core.mouse }
    }

    core.blockTouchMove(_isTouching)

    let imageElement = page.imageContainer?.querySelector(".annotations-cont");
    if (core.isUnderMouse(imageElement, _cursor)) {
        hoveredWordList = _CollectWordListUnderCursor(page, cfg, _cursor);
    }
    if (hoveredWordList.length === 0) {
        return []
    }

    // assumes the list is sorted by priority
    let hoveredWord = hoveredWordList[0];
    let shouldOpenModal = false

    if (hasMouse) {
        let eventType = core.event?.type ?? ""
        switch (eventType) {
            case "mousedown":
                _mouseDownTarget = hoveredWord
                break;
            case "mouseup":
                shouldOpenModal = _mouseDownTarget === hoveredWord && hoveredWord !== null
                _mouseDownTarget = null;
                break;
        }
    }

    if (gesture && gesture.inspectMode && gesture.done) {
        shouldOpenModal = true
        _cursor = geom.zeroPoint()
    }

    if (shouldOpenModal) {
        setTimeout(() => {
            core.openModal(hoveredWordList, editModalView, 1);
        }, 100)
    }

    return hoveredWordList;
}

type GestureInfo = {
    started: number
    firstMove: number // timestamp

    inspectMode: boolean
    done: boolean

    tid0: number
    touchCount: number,
}

let _gesture: GestureInfo | null = null
function _DoGestureRecognition(...elements: HTMLElement[]): GestureInfo | null {
    if (core.event) {
        // core.debugVar({eventType: core.event.type})

        if (core.event.type === "touchstart") {
            let targetMatch = elements.length === 0
            let target = core.event.target as HTMLElement
            for (let e of elements) {
                if (target === e || e.contains(target)) {
                    targetMatch = true
                    break
                }
            }
            if (!targetMatch) {
                return null
            }

            let started = Date.now()
            _gesture = {
                started: started,
                firstMove: 0,
                inspectMode: false,
                done: false,
                tid0: core.touchIdByIndex(0),
                touchCount: core.touches.length,
            }

            const longTouch = 200 // ms
            setTimeout(() => {
                if (_gesture != null && _gesture.started == started && _gesture.firstMove < _gesture.started) {
                    _gesture.inspectMode = true
                }
                core.scheduleRedraw()
            }, longTouch)
        }

        if (_gesture) {
            if (core.event.type === "touchmove") {
                _gesture.firstMove = Date.now()
            }

            if (core.event.type === "touchend") {
                _gesture.done = true
                setTimeout(() => {
                    if (_gesture && _gesture.done) {
                        _gesture = null
                        core.scheduleRedraw()
                    }
                }, 0)
            }
        }
    }

    if (_gesture) {
        return { ..._gesture }
    } else {
        return null
    }
}


const enFontFamily = `"Short Stack"`
const jpFontFamily = `"Kosugi Maru"`
const numFontFamily = `"Trebuchet MS"`

const cssFonts: css.RuleDefinition = {
    ".num": {
        fontFamily: numFontFamily,
    },
    ".jp": {
        fontFamily: jpFontFamily
    },
    ".en": {
        fontFamily: enFontFamily
    },
}

const clsFontPrefetcher = css.cls("font-prefetcher", {
    position: "fixed",
    top: "100000px",
    opacity: "0.1",
    pointerEvents: "none",
    ...cssFonts
})


const clsPinnedNote = css.cls("pinned-note", {
    // position: "absolute",
    fontSize: `${NOTE_FONT_SIZE}px`,
    cursor: "pointer",

    fontFamily: `${enFontFamily}, sans-serif`,
    fontWeight: "400",
    fontStyle: "normal",

    padding: "2px",

    "&:hover": {
        overflow: "visible",
        borderColor: "transparent",
    },

    // needs to closely match the way we draw the note idx on the canvas
    ".idx": {
        display: "inline-block",
        border: "none",
        borderRadius: "10px",
        width: "10px",
        height: "10px",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "9px",
        color: "white",
        marginRight: "10px",

        verticalAlign: "top",
        marginTop: "2px",
    },

    ".reading": {
        // the furigana stuff
        // display: "inline-block",
        marginRight: "10px",
        fontWeight: 400,
        fontStyle: "normal",
    },

    ".kanji": {
        border: "none",
        borderRadius: "2px",
        color: "white",
        padding: "2px 4px",
    },

    ...cssFonts,
});


const overlap_tolerance = 2
const overlap_transition = 3
const head_overlap_tolerance = 8
const head_overlap_extention = 5

function lineContains(container: geom.Line, smaller: geom.Line, cfg: PageViewConfig) {
    const tolerance = overlap_tolerance / cfg.zoomFactor;
    return (
        geom.distanceFromPointToLine(smaller[0], container) < tolerance &&
        geom.distanceFromPointToLine(smaller[1], container) < tolerance
    );
}

function lineHeadsOverlap(line1: geom.Line, line2: geom.Line, cfg: PageViewConfig) {
    const tolerance = head_overlap_tolerance / cfg.zoomFactor;
    return geom.pointDist(line1[0], line2[0]) < tolerance;
}

type PageViewConfig = {
    containerSize: geom.Size;
    imageRect: geom.Rect;
    zoomFactor: number;
    sideMargin: number;
    mobileMode: boolean;
};

function pinUserNote(page: ImagePage, note: UserNote) {
    let word = note.annotation
    core.scheduleRedraw();
    let cfg = _ImagePageViewConfig(page);

    let rects = word.BBoxes;

    let orientation = word.Orientation;
    let thickness = 1
    if (orientation == server.TextVertical) {
        let width = geom.pointDist(rects[0].Points[0], rects[0].Points[1])
        thickness = width / 6
    } else {
        let width = geom.pointDist(rects[0].Points[0], rects[0].Points[3])
        thickness = width / 6
    }
    thickness = thickness * cfg.zoomFactor
    thickness = Math.min(thickness, 3)

    let point = rects[0].Points[0]
    point = geom.pointScale(point, cfg.zoomFactor);
    // NOTE: no need to do the same thing to `y` because the imageRect and the
    // notes have the same starting point for y
    point.x += cfg.imageRect.x

    let lines: geom.Line[] = [];
    for (let rect of word.BBoxes) {
        let line: geom.Line;
        if (orientation === server.TextVertical) {
            line = [rect.Points[0], rect.Points[3]];
        } else {
            line = [rect.Points[3], rect.Points[2]];
        }
        line = geom.parallelTransition(line, -thickness);
        lines.push(line);
    }

    function adjustLinePosition(line: geom.Line, otherLine: geom.Line) {
        if (lineContains(line, otherLine, cfg)) {
            // core.debugVar({ overlap: other.idx });
            let moved = geom.parallelTransition(line, -overlap_transition * thickness);
            line[0] = moved[0];
            line[1] = moved[1];
        }
        /*
        if (lineHeadsOverlap(line, otherLine, cfg)) {
            let longer = line
            if (geom.lineLength(otherLine) > geom.lineLength(line)) {
                longer = otherLine
            }
            let distPixels = -head_overlap_extention * thickness;
            let t = distPixels / geom.lineLength(longer)
            longer[0] = geom.interpolateLine(longer, t)
        }
        */
    }

    let idx = (page.pinnedUserNotes.length + 1).toString(32);
    // Check if lines overlap with another pinned note and if so increase level
    for (let other of page.pinnedUserNotes) {
        for (let otherLine of other.pin.lines) {
            for (let line of lines) {
                adjustLinePosition(line, otherLine)
                adjustLinePosition(otherLine, line)
            }
        }
    }

    note.pin = {
        thickness,
        lines,
        color: hilightColors[page.pinnedUserNotes.length % hilightColors.length],
    }

    page.pinnedUserNotes.push(note as PinnedUserNote);
}

const clsBottomPins = css.cls("bottom-pins", {
    position: "fixed",
    bottom: "0px",
    display: "flex",
    flexWrap: "wrap",
    gap: "2px",
    padding: "4px",
    width: "100%",
    background: "whitesmoke",
    overflow: "scroll",
    maxHeight: "200px",
});

const clsLeftPins = css.cls("bottom-pins", {
    position: "fixed",
    left: "0px",
    top: layout.TOPBAR_HEIGHT + "px",
    bottom: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "4px",
    background: "whitesmoke",
    overflow: "auto",
});

function drawPinnedNotes(page: ImagePage, cfg: PageViewConfig): VNode {
    let notes = page.pinnedUserNotes
    return <>
        {!cfg.mobileMode && (
            // desktop view
            <div class={clsLeftPins} style={{width: cfg.sideMargin}}>
                {notes.map((note) => drawPinnedNote(page, note, cfg))}
            </div>
        )}
        {cfg.mobileMode && (
            // mobile view!
            <div class={clsBottomPins} {...events.elementRefAttrs(refs.ref(page, 'pins'))} >
                {notes.map((note) => drawPinnedNote(page, note, cfg))}
            </div>
        )}
    </>
}

function drawPinnedNote(page: ImagePage, note: PinnedUserNote, cfg: PageViewConfig): VNode {
    let pin = note.pin
    let style: preact.JSX.CSSProperties = {
        color: pin.color,
    }

    let lookup = page.data.Lookup[note.annotation.Text]
    let entry = rebuildWordEntry(note.annotation, lookup, note.wordId)

    return (
        <div
            class={clsPinnedNote}
            style={style}
            onClick={editOnClick}
            {...events.assocAttrs(note)}
        >
            {/* <span class="idx num" style={{background: pin.color}}>{pin.idx}</span> */}
            {entry.Text !== entry.Reading && (<>
                <span class="jp kanji" style={{background: pin.color}}>{entry.Text}</span>
                <span> </span>
            </>)}
            <span class="jp">{entry.Reading}</span>
            <span> </span>
            <span class="en">{note.selected.join(", ")}</span>
            {/* {note.note} */}
        </div>
    );
}

function editOnClick(event: Event) {
    let note = events.readAssoc<PinnedUserNote>(event.currentTarget)!;
    core.openModal([note.annotation], editModalView, 1);
}

const clsEditModal = css.cls("edit-modal", {
    borderRadius: "6px",
    background: "white",
    boxShadow: "0px 0px 10px",
    padding: "10px 0px",
    maxWidth: "600px",
    width: "calc(100% - 20px)",
    height: "calc(100% - 120px)",
    overflow: "auto",
    "label.label": {
        display: "block",
        marginBottom: "20px",
        input: {
            display: "block",
            width: "90%",
            fontSize: "22px",
            padding: "6px 8px",
        },
    },
});

function editModalView(words: server.TextAnnotation[]): VNode {
    let page = core.getPageData();
    if (!(page instanceof ImagePage)) {
        return <></>;
    }

    return (
        <div class={clsEditModal}>
            {wordsEditBox(page, words)}
        </div>
    );
}

// ============================================================================
// Word List Popup
// ============================================================================

const clsWordEditBox = css.cls("word-note-box", {
    display: "flex",
    flexFlow: "column nowrap",

    fontSize: NOTE_FONT_SIZE + "px",

    borderTop: "2px solid black",
    "&:last-child": {
        borderBottom: "none",
        marginBottom: "20px",
    },

    ".togglable": {
        padding: "4px 8px",
        borderRadius: "3px",
        border: "1px dotted hsla(0, 0%, 90%, 80%)",
        lineHeight: "40px",

        cursor: "pointer",

        "&:hover": {
            border: "1px dotted hsla(0, 0%, 90%, 50%)",
            background: "whitesmoke",
        },
        "&.active": {
            border: "1px solid silver",
            background: "lavender",
        }
    },

    "> div.entry_header": {
        fontFamily: `${jpFontFamily}, sans-serif`,
        fontWeight: "bold",
        fontSize: "24px",
        alignSelf: "flex-start",

        width: "100%",

        display: "flex",
        alignItems: "center",
        gap: "10px",

        padding: "0px 10px",

        ".jp": {
            flexShrink: 0,
            fontFamily: jpFontFamily,
        },

        ".kanji": {
            border: "none",
            borderRadius: "2px",
            color: "white",
            padding: "2px 4px",

            background: "black",
        },

        "&.unlikely": {
            opacity: "0.5",
        },

        ".collapsed_preview": {
            fontSize: "70%",
            color: "gray",
            fontFamily: enFontFamily,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        }
    },

    "> div.sense": {
        borderTop: "1px solid gray",
        padding: "2px 0px",

        display: "flex",
        flexFlow: "row nowrap",
        fontFamily: `${enFontFamily}, sans-serif`,
        // fontSize: NOTE_FONT_SIZE + "px",
        gap: "10px",
        "> span.sense_no": {
            font: numFontFamily,
            color: "gray",
            fontSize: "12px",
            flexShrink: 0,
            width: "20px",
            height: "20px",
            lineHeight: "18px",
            textAlign: "center",
            borderRadius: "10px",
            border: "1px solid gray",

            transform: "translate(5px, 10px)",
        },
        "> div > span.comma": {
            whiteSpace: "pre",
            opacity: 0.5,
        },
        "> div > span.gloss": {
            color: "black",
        }
    }
})

type WordEntry = {
    WordId: number
    Senses: server.MeaningSense[]

    // basically guessing .. but should work 99% of the time
    // the cases where it probably won't work properly are
    // when the word entry has readings that are restricted
    // in terms of which writings they apply to, because we
    // haven't thought properly about how to solve that
    Text: string
    Reading: string
}

function rebuildWordEntry(annotation: server.TextAnnotation, lookup: server.WordLookup, wordId: number): WordEntry {
    let senses = lookup.Senses.filter(s => s.WordId === wordId)
    let text = annotation.Text
    let reading = annotation.Reading
    let s0 = senses[0]
    if (s0) {
        if (text === reading && s0.Writings.length > 0) {
            text = s0.Writings[0]
        }
        if (!s0.Readings.includes(reading)) {
            reading = s0.Readings[0]
        }
    }
    return {
        WordId: wordId,
        Senses: senses,
        Text: text,
        Reading: reading,
    }
}

function _wordItemHeader(listState: AnnotationListState, index: number, entry?: WordEntry) {
    let annotation = listState.annotations[index]
    let note = useUserNote(annotation, entry?.WordId ?? 0)
    let text = annotation.Text
    let reading = annotation.Reading
    let unlikely = false
    if (entry) {
        unlikely = reading !== entry.Reading
        text = entry.Text
        reading = entry.Reading
    }

    let meaningPreview = ""

    let icon = "⏴" // default for no sub items
    if (entry) {
        if (listState.collapsed.get(entry.WordId)) {
            icon = "⏵"
            meaningPreview = entry.Senses.filter(s => !s.Archaic && s.Glossary.length > 0).slice(0, 3).map(s => s.Glossary[0]).join(", ")
        } else {
            icon = "⏷"
        }
    }

    return <div class={ui.classes("entry_header", unlikely && "unlikely")}>
        <button onClick={wordPanelOnClickToggleExpand} disabled={!entry}
            {...events.assocAttrs(listState, "list")} data-word-id={entry?.WordId ?? 0}>{icon}</button>
        <div class={ui.classes("jp togglable",  note.explicitPin && "active")} onClick={wordPanelOnClickToggleExplicitPin} {...events.assocAttrs(note, "note")}>
            {text != reading && <><span class="kanji">{text}</span> </>}
            <span>{reading}</span>
        </div>
        {meaningPreview && <span class="collapsed_preview">{meaningPreview}</span>}
    </div>
}

type AnnotationListState = {
    annotations: server.TextAnnotation[],
    lookups: server.WordLookup[],
    collapsed: Map<number, boolean>
}

const useWordListState = cache.declareHookN((page: ImagePage, annotations: server.TextAnnotation[]): AnnotationListState => {
    let lookups = annotations.map(a => page.data.Lookup[a.Text])
    let collapsed = new Map<number, boolean>()
    let value = false
    for (let lookup of lookups) {
        if (!lookup || lookup.WordIds.length == 0) {
            continue
        }
        for (let wordId of lookup.WordIds) {
            collapsed.set(wordId, value)
            if (!value) { // hack so only the first item is not collapsed
                value = true
            }
        }
    }
    return { annotations, lookups, collapsed }
})

function wordsEditBox(page: ImagePage, annotations: server.TextAnnotation[]): VNode[] {
    let listState = useWordListState(page, annotations)
    let lookups = listState.lookups
    return annotations.map((annotation, i) => {
        const lookup = lookups[i]
        if (!lookup || lookup.WordIds.length === 0) {
            return <div class={clsWordEditBox}>
                {_wordItemHeader(listState, i)}
            </div>
        } else {
            return <>
                {lookup.WordIds.map(wordId => {
                    let entry = rebuildWordEntry(annotation, lookup, wordId)
                    let note = useUserNote(annotation, wordId)
                    return  <div class={clsWordEditBox} key={wordId}>
                        {_wordItemHeader(listState, i, entry)}
                        {!listState.collapsed.get(wordId) && entry.Senses.map((sense, senseIdx) =>
                            <div key={sense.SenseId} class="sense">
                                <span class="sense_no">{senseIdx + 1}</span>
                                <div>
                                {sense.Glossary.map((gloss, glossIdx) => {
                                    const key = `gloss_${sense.SenseId}_${glossIdx}`
                                    return <>
                                    {glossIdx > 0 && <span class="comma">, </span>}
                                    <> </> {/* breakable space; otherwise text will not break!!! */}
                                    <span key={key}
                                        class={ui.classes("gloss togglable", note.selectedKeys.includes(key) && "active")}
                                        onClick={wordPanelOnClickSelect}
                                        {...events.assocAttrs(note, "note")} {...events.assocAttrs(gloss, "gloss")} {...events.assocAttrs(key, "key")}>
                                        {gloss}
                                    </span>
                                </>})}
                                </div>
                            </div>
                        )}
                    </div>
                })}
            </>
        }
    })
}

function _managePinning(page: ImagePage, note: UserNote) {
    if (note.pin && note.selected.length === 0 && !note.explicitPin) {
        utils.removeListItem(page.pinnedUserNotes, note)
        note.pin = null
    }
    if (!note.pin && (note.selected.length > 0 || note.explicitPin)) {
        pinUserNote(page, note)
    }
}

function wordPanelOnClickToggleExpand(event: Event) {
    let list = events.readAssoc<AnnotationListState>(event.currentTarget, "list")!;
    let wordId = events.readNumberAttr(event.currentTarget, "data-word-id")
    let collapsed = list.collapsed.get(wordId) ?? false
    list.collapsed.set(wordId, !collapsed)
    core.scheduleRedraw()
}

function wordPanelOnClickToggleExplicitPin(event: Event) {
    if (document.getSelection()?.type === "Range") {
        console.log("selection!!")
        return
    }
    let page = core.getPageData() as ImagePage;
    let note = events.readAssoc<UserNote>(event.currentTarget, "note")!;
    utils.assert(note, "note is null")
    note.explicitPin = !note.explicitPin
   _managePinning(page, note)
   core.scheduleRedraw()
}

function wordPanelOnClickSelect(event: Event) {
    if (document.getSelection()?.type === "Range") {
        return
    }
    let page = core.getPageData() as ImagePage;
    let note = events.readAssoc<UserNote>(event.currentTarget, "note")!;
    utils.assert(note, "note is null")
    let gloss = events.readAssoc<string>(event.currentTarget, "gloss")!;
    let key = events.readAssoc<string>(event.currentTarget, "key")!;
    utils.toggleListItem(note.selected, gloss)
    utils.toggleListItem(note.selectedKeys, key)
    _managePinning(page, note)
    core.scheduleRedraw()
}
