import * as core from "@lib/core";
import * as rpc from "@lib/rpc";
import * as server from "@auth/server";

const KAuthToken = "authToken";
const HAuthToken = "x-auth-token";

export let gUser: server.User | null = null;

export function setSessionInfo(info: server.SessionInfoResponse) {
    gUser = info.User;
    setAuthToken(info.Token);
}

export function clearSessionInfo() {
    gUser = null;
    localStorage.removeItem(KAuthToken);
}

export function setAuthToken(token: string) {
    localStorage.setItem(KAuthToken, token);
    rpc.setAuthHeaders({
        [HAuthToken]: token,
    });
    document.cookie = `${KAuthToken}=${token}; path=/`
}

export function initAuthHeader(): boolean {
    const token = localStorage.getItem(KAuthToken) ?? "";
    if (token) {
        rpc.setAuthHeaders({
            [HAuthToken]: token,
        });
    }
    return token !== "";
}

export function logout() {
    const token = localStorage.getItem(KAuthToken) ?? "";
    clearSessionInfo();
    if (token) {
        server.UserLogout({ Token: token });
    }
    core.scheduleRedraw();
}

// helpers

export function isAdmin(): boolean {
    return gUser?.IsAdmin == true;
}

// TODO: autogenerate along with the RPC bindings & server types
const InvalidSessionToken = "InvalidSessionToken"; // IMPORTANT must be in sync with backend

// to be called once during the initilization of the app, before initializing routes
export async function validateAuthToken() {
    if (initAuthHeader()) {
        const [data, error] = await server.UserTokenVerify({});
        if (data !== null) {
            setSessionInfo(data);
        } else {
            if (error === InvalidSessionToken) {
                clearSessionInfo();
            }
        }
        core.scheduleRedraw();
    }
}
