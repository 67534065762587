import * as rpc from "@lib/rpc"

export type AnnotationLevel = number;
export const LevelBlock: AnnotationLevel = 0;
export const LevelParagraph: AnnotationLevel = 1;
export const LevelWord: AnnotationLevel = 2;
export const LevelChar: AnnotationLevel = 3;

export type TextOrientation = number;
export const TextHorizontal: TextOrientation = 0;
export const TextVertical: TextOrientation = 1;

export interface RegisterRequest {
    Name: string
    Password: string
    Email: string
}

export interface SessionInfoResponse {
    Token: string
    User: User
}

export interface LoginRequest {
    Name: string
    Password: string
}

export interface Empty {
}

export interface VerificationCodeInput {
    Code: string
}

export interface UploadResponse {
    Name: string
    ImageURL: string
    Words: TextAnnotation[]
    Lookup: Record<string, WordLookup>
}

export interface LoadImageRequest {
    ImageName: string
}

export interface LoadImageResponse {
    ImageURL: string
    Words: TextAnnotation[]
    Lookup: Record<string, WordLookup>
}

export interface User {
    Id: number
    Name: string
    Email: string
    IsAdmin: boolean
    EmailVerified: boolean
}

export interface TextAnnotation {
    Id: number
    Level: AnnotationLevel
    Orientation: TextOrientation
    Confidence: number
    BBoxes: Rect[]
    Text: string
    Reading: string
}

export interface WordLookup {
    WordIds: number[]
    Senses: MeaningSense[]
}

export interface Rect {
    Points: Point[]
}

export interface MeaningSense {
    SenseId: number
    WordId: number
    Glossary: string[]
    Readings: string[]
    Writings: string[]
    Archaic: boolean
}

export interface Point {
    x: number
    y: number
}

export async function UserRegister(data: RegisterRequest): Promise<rpc.Response<SessionInfoResponse>> {
    return await rpc.call<SessionInfoResponse>('UserRegister', JSON.stringify(data));
}

export async function UserLogin(data: LoginRequest): Promise<rpc.Response<SessionInfoResponse>> {
    return await rpc.call<SessionInfoResponse>('UserLogin', JSON.stringify(data));
}

export async function UserLogout(data: Empty): Promise<rpc.Response<Empty>> {
    return await rpc.call<Empty>('UserLogout', JSON.stringify(data));
}

export async function UserTokenVerify(data: Empty): Promise<rpc.Response<SessionInfoResponse>> {
    return await rpc.call<SessionInfoResponse>('UserTokenVerify', JSON.stringify(data));
}

export async function RequestVerificationCode(data: Empty): Promise<rpc.Response<Empty>> {
    return await rpc.call<Empty>('RequestVerificationCode', JSON.stringify(data));
}

export async function CheckVerificationCode(data: VerificationCodeInput): Promise<rpc.Response<Empty>> {
    return await rpc.call<Empty>('CheckVerificationCode', JSON.stringify(data));
}

export async function UploadImage(data: BodyInit): Promise<rpc.Response<UploadResponse>> {
    return await rpc.call<UploadResponse>('UploadImage', data);
}

export async function LoadImage(data: LoadImageRequest): Promise<rpc.Response<LoadImageResponse>> {
    return await rpc.call<LoadImageResponse>('LoadImage', JSON.stringify(data));
}

