import * as preact from "preact";
import { VNode } from "preact";

import * as refs from "@lib/refs";
import * as rpc from "@lib/rpc";
import * as core from "@lib/core";
import * as cache from "@lib/cache";
import * as ui from "@lib/ui";
import * as geom from "@lib/geom";
import * as css from "@lib/css";
import * as events from "@lib/events";
import * as utils from "@lib/utils";

import * as auth from "@auth/auth";
import * as auth_forms from "@auth/forms";

import * as server from "./server";

import * as layout from "./layout";


class LandingPage {}

export function makeLandingPage(_route: string, _prefix: string) {
    return rpc.asResponse(new LandingPage());
}

export function viewLandingPage(_route: string, _prefix: string, _vm: LandingPage): VNode {
    return layout.landingPage(landingPageContent())
}

const clsLandingContainer = css.cls("hp-container", {
    background: "white",
    padding: "0px",
    margin: "0px auto",
    width: "100%",
    maxWidth: "1100px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "stretch",
    "> hr": {
        width: "100%",
    },
    paddingBottom: "100px",
})

const clsTwoItemWide = css.cls("lp-two-wide", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",

    "> div": {
        boxSizing: "border-box",
        padding: "10px 30px",
    },
    "> div.narrow": {
        flex: 1,
        minWidth: "350px",
    },
    "> div.wide": {
        flex: 2,
        minWidth: "500px",
    },

    "img": {
        maxWidth: "calc(100% - 20px)",
    }
})

const clsTwoItemNarrow = css.cls("lp-two-narrow", {
    display: "flex",
    flexDirection: "column",
    "> div": {
        padding: "0px 20px",
    },
    "img": {
        maxWidth: "calc(100% - 20px)",
    }
})

const clsVideo = css.cls("video", {
    borderRadius: "4px",
    border: "1px solid black",
    width: "100%",
})

const clsCallout = css.cls("cta", {
    display: "inline-block",
    margin: "20px",
    background: "RoyalBlue",
    color: "white",
    padding: "16px 30px",
    textDecoration: "none",
    border: "none",
    borderRadius: "8px",
    fontSize: "30px",
    boxShadow: "0px 1px 1px 0px black, 0px 0px 2px 0px silver",
    textShadow: "0px -1px 1px black",
})

const clsCallout2 = css.cls("cta", {
    display: "inline-block",
    margin: "16px",
    background: "RoyalBlue",
    color: "white",
    padding: "14px 20px",
    textDecoration: "none",
    border: "none",
    borderRadius: "8px",
    fontSize: "24px",
    boxShadow: "0px 1px 1px 0px black, 0px 0px 2px 0px silver",
    textShadow: "0px -1px 1px black",
})

const clsCentering = css.cls("centering", {
    display: "flex",
    placeContent: "center",
    gap: "20px",
    margin: "20px",
})

const clsSellingPointsWide = css.cls("selling-points-wide", {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "> div": {
        maxWidth: "calc(50% - 50px)",
        minWidth: "350px",
    },
    "p": {
        margin: "10px 0px",
    },
    "h4": {
        textDecoration: "underline",
        margin: "20px 0px",
    },
})

const clsSellingPointsNarrow = css.cls("selling-points-narrow", {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "> div": {
        padding: "0px 20px",
    },
    "p": {
        margin: "10px 0px",
    },
    "h4": {
        textDecoration: "underline",
        margin: "10px 0px",
    },
})

const clsHeader = css.cls("header", {
    "img": {
        width: "100%",
    }
})

const clsImgProfile = css.cls("image-circle", {
    borderRadius: "12px",
    overflow: "hidden",
    boxShadow: "0px 1px 20px 0px silver",
    width: "100px",
    height: "100px",
})

const clsBackgroundStory = css.cls("background-story", {
    padding: "20px",
    columns: "auto 240px",

})

function landingPageContent(): VNode {
    // core.debugVar({version: VERSION})
    const threshold = 500
    let wsize = core.getWindowSize()
    let isWide = wsize.width > threshold

    let imgSuffix = isWide ? "wide" : "narrow"
    let img = (name: string) => "/static/images/" + name + "-" + imgSuffix + ".webp"

    let clsTwoItem = isWide ? clsTwoItemWide : clsTwoItemNarrow;
    let clsSellingPoints = isWide ? clsSellingPointsWide : clsSellingPointsNarrow;
    return (
        <div class={clsLandingContainer}>
            <div class={clsHeader}>
                <img src={img("lp-header")} />
            </div>
            <div class={clsTwoItem}>
                <div class="narrow">
                    {/* <h1 class={clsProductName}>読みたい</h1> */}
                    <p><strong>Yomitai</strong> is a web based tool that serves as <strong><u>your reading assistance for Japanese</u></strong>.</p>

                    <p>Here's how it works:</p>

                    <p>1) <strong>Upload</strong> picture from book</p>

                    <p>2) <strong>Point</strong> at words you don&#39;t know</p>

                    <p>3) <strong>Yomitai</strong> shows you the reading and meaning</p>

                    <p>4) <strong>Pin</strong> to the margins as a colored annotation</p>

                    <p>5) <strong>Enjoy</strong> reading Japanese material &amp; improving your language level</p>
                </div>
                <div class="wide">
                    <img src={img("lp-example1")} />
                </div>
            </div>
            <hr />
            <div class={clsTwoItem}>
                <div>
                    <h3>You need to give this a try!</h3>
                    <p>For a limited amount of time, Yomitai is open for public beta testing.</p>
                    <p>No signup required! Start using it right away!</p>
                </div>
                <div class={clsCentering}>
                    <a class={clsCallout2} href="/#/upload">Try it Now!</a>
                </div>
            </div>
            <hr />
            <div class={clsSellingPoints}>
                <div>
                    <h4>Don't break your reading flow</h4>
                    <p>As a language learner, you often have to pause to lookup new kanji and words. This breaks your reading flow and slows you down.</p>
                    <p>Imagine if you had a companion who could tell you immediately what this word means by just pointing at it.</p>
                </div>

                <div>
                    <h4>Color coded annotation</h4>
                    <p>Pin annotations to the margin to avoid cluttering the main text. Use colors for quick lookup. Avoid dependence on furigana.</p>
                    <p>Yomitai is designed to be a language learning assistant. It helps you without undermining the learning process.</p>
                </div>

                <div>
                    <h4>Works with vertical text & real life photos</h4>
                    <p>You can use it on camera pictures of books, signs, menus, etc, and it still works. The OCR system used by Yomitai is flexible enough to recognize text in a wide variety of situations.</p>
                </div>

                <div>
                    <h4>No subscription fees!</h4>
                    <p>Yomitai is a one-time-payment product.<br />
                    Pay once and gain access for a lifetime.</p>
                    <p>What's more: <em>for a limited time</em>, access is open to everyone <em>for free</em>!</p>
                </div>
            </div>

            <div class={clsCentering}>
                <img style={{width: "calc(100% - 20px)"}} src={img("lp-example2")} />
            </div>

            <hr />

            <div class={clsTwoItem}>
                <div class="wide">
                    <h3>One time payment; life-time access</h3>
                    <p>For a limited time period, you can pre-order your life time access at a 60% discount</p>
                </div>
                <div class="narrow">
                    <a class={clsCallout2} href={layout.discountUrl}>Pre-Order 60% off</a>
                </div>
            </div>

            <hr />

            <div class={clsBackgroundStory}>
                    <h4><u>Background</u> & why I'm working on this project</h4>

                    <p>Yomitai is a system that I'm developing primarily for myself, as a reading assistant, to help me improve my Japanese reading.</p>

                    <p>I've been passively learning Japanese for many years. I've already passed the JLPT N2 examination, and I'm currently preparing for taking the N1 example in the summer.</p>

                    <p>I'm providing it because believe it will be of value to other people in a similar situation: you know enough Japanese that you feel like you should be able to read real Japanese, but when you go ahead and try to do it, you feel hindered by all the kanji you still don't know how to read.</p>

                    <p>Some people really like to spend hours memorizing Kanji and Vocabulary using flash cards and memorization techniques.</p>

                    <p>That doesn't really work for me. I never found the appeal.</p>

                    <p>I believe the best way to learn is by reading material in the native language, meant for native speakers, and absorbing the language via immersion. This way you not only learn the vocabulary, but you see how sentences are structured, and how ideas flow through the text. You subconsciously abosrb the various nuances in word usage and various ways to structure sentences.</p>
                    <h4><u>The design of Yomitai</u></h4>

                    <p>The most important aspect when reading is (1) reducing friction (2) without undermining.</p>

                    <p>Yes, you can pause to look up the words you don't know. But that introduces friction.</p>

                    <p>Yes, you can use a browser plugin to show a popup on hover, but it goes away and you have to hover again if you forget the reading or meaning. Plus, it only works with plain text content; not with pictures.</p>

                    <p>Yes, you can put furigana all over the text, but that undermines the learning process as you become accustomed to the furigana and never get a chance to train your mind to read text in Kanji without annotations.</p>

                    <p>I've developed an analog system for myself based on these ideas, and it's very simple: using a few colored pens (or highlighers), underline (or highlight) and word or kanji I don't know, look it up, write notes on the margin</p>
                    <p>The color serves as a lookup index: when I read the text for the second or third time, if I can't remember that word or that kanji, I look on the margins for the note written in the same color.</p>

                    <p>I don't claim this system is particularly genius. I think many learners end up doing something similar. If there's anything particularly unique about this system, it's the combination of colors and numbers for quick lookup.</p>

                    <p>Now, this process takes a lot of time. Looking up Kanji is a tedious process. But it pays off dividends: once the annotations are there, they are immensly helpful when you go over the text again and again.</p>

                    <p>Yomitai is the digitalization of this process. It automates it to remove the friction even from the initial step of looking things up.</p>
            </div>
            <hr />
            <div style={{ display: "flex", flexWrap: "wrap", placeContent: "center" }}>
                <a class={clsCallout2} href="/#/upload">Try it Now!</a>
                <a class={clsCallout2} href={layout.discountUrl}>Pre-Order 60% off</a>
            </div>
            <hr />
            <div class={clsTwoItem}>
                <div class={"narrow " + clsCentering}>
                    <img class={clsImgProfile} src="/static/images/judi-systems.webp" />
                    <img class={clsImgProfile} src="/static/images/ai-pfp.webp" />
                </div>
                <div>
                    <p>Yomitai is provided by <a href="https://judi.systems">Judi Systems</a>; a sole proprietorship based in Tokyo.</p>
                    <p>For feedback, bug reports, and feature requests, send an email to <code>yomitai.app@judi.systems</code></p>
                </div>
            </div>
        </div>
    );
}
