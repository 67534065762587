import * as preact from "preact";
import { VNode } from "preact";

import * as core from "@lib/core";
import * as css from "@lib/css";

// copied from shinjikai
export interface ScreenInfo {
    screenWidth: number;
    layoutWidth: number;
    isWide: boolean;
}
export const MAX_LAYOUT_WIDTH = 1200;
export const WIDE_LAYOUT_THRESHOLD = 600;
export function getScreenInfo() {
    let screenWidth = document.body.clientWidth;
    let layoutWidth = MAX_LAYOUT_WIDTH;
    if (layoutWidth > screenWidth) {
        layoutWidth = screenWidth;
    }
    let isWide = screenWidth > WIDE_LAYOUT_THRESHOLD;
    return { screenWidth, layoutWidth, isWide };
}

const clsHeaderLink = css.cls("header-link", {
    font: "bold 14px/16px sans-serif",
    textDecoration: "none",
    "> img": {
        height: "30px",
        marginRight: "4px",
    }
})

const clsHeaderLinkN = css.cls("header-link-n", {
    font: "bold 10px/10px sans-serif",
    display: "inline-block",
    textAlign: "center",
    textDecoration: "none",
    // background: "white",
    // color: "RoyalBlue",
    // padding: "2px 4px",
    // border: "1px solid white",
    // borderRadius: "4px",
    "img": { // icons
        height: "24px",
    },
})

export const TOPBAR_HEIGHT = 60

const clsTopbar = css.cls('top-users-bar', {
    width: '100%',
    height: TOPBAR_HEIGHT + 'px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    border: "none",
    background: "RoyalBlue",
    color: "white",
    fontSize: "14px",
    "> div": {
        display: 'flex',
        // alignItems: 'center',
        gap: '20px',
        height: TOPBAR_HEIGHT + 'px',
        width: "calc(100% - 20px)",
        maxWidth: "900px",
        "h1": {
            fontSize: "20px",
            margin: "0",
            display: "inline-block",
        },
        "small": {
            fontSize: "9px",
            paddingTop: "10px",
            paddingLeft: "4px",
        },
        "a": {
            color: "inherit",
            display: "flex",
            alignItems: "center",
            gap: "3px",
        },
        '> *': {
            margin: "0",
        },
    }
})

export const discountUrl = "https://store.judi.systems/checkout/buy/d235a354-6565-4375-ac02-03d276018187?checkout%5Bdiscount_code%5D=BETA2024"

export function page(content: VNode): VNode {
    let screen = getScreenInfo()
    let clsLink = screen.isWide ? clsHeaderLink : clsHeaderLinkN;
    return (<>
        <div class={clsTopbar}>
            <div>
                <a class={clsLink} href="#/">
                <img src="/images/icon.webp" style={{ height: 50, mixBlendMode: "luminosity" }} />
                {screen.isWide && <h1>Yomitai</h1>}
                {screen.isWide && <small>Open Beta</small>}
                </a>
                {false && <a class={clsLink} target="_blank" href={discountUrl}>
                    {screen.isWide && <>Pre-Order at 60% discount</>}
                    {/* {!screen.isWide && <img src="/images/icons/pre-order-60-off.svg" />} */}
                    {!screen.isWide && <span class="link_n">Pre-Order<br />60% off</span>}
                </a>}
                <div style={{flex: 1, minWidth: "60px"}} /> {/* minWidth to take space for the touch handler in mobile view */}
                <a class={clsLink} target="_blank" href="https://x.com/Yomitai_App">
                    <img src="/images/icons/x.svg" />
                </a>
                <a class={clsLink} href="#/upload">Upload New</a>
                <span />
            </div>
        </div>
        {content}
        {core.debugVarsPanel()}
    </>)
}

export function landingPage(content: VNode): VNode {
    return (<>
        {content}
        {core.debugVarsPanel()}
    </>)
}
