import * as preact from "preact";
import { VNode } from "preact";

import * as rpc from "@lib/rpc";
import * as core from "@lib/core";
import * as ui from "@lib/ui";
import * as css from "@lib/css";
import * as events from "@lib/events";

import * as server from "./server";
import * as layout from "./layout";

const clsUploadForm = css.cls("upload-form", {
    margin: "100px",
    padding: "20px",

    display: "flex",
    flexDirection: "column",
    gap: "40px",
    alignItems: "center",

    "input[type=file]": {
        display: "none",
    },

    label: {
        padding: "10px 20px",
    },
});

const clsUploadNotes = css.cls("pre-upload-copy", {
    color: "Jet",
    textShadow: "0px 1px 1px white",
})

const clsPasteField = css.cls("paste-field", {
    border: "4px dashed slategray",
    borderRadius: "10px",
    padding: "100px 140px",
    caretColor: "transparent",

    fontSize: "30px",
    textAlign: "center",
    color: "slategrey",

    ":focus, &.dropping": {
        outline: "none",
        borderColor: "slateblue",
        boxShadow: "0px 0px 10px 0px slateblue inset",
        background: "white",
        color: "darkslateblue",
    },
});

const clsError = css.cls("error-message", {
    color: "red",
    fontSize: "20px",
    padding: "20px",
});


function onInputBlock(event: Event) {
    event.preventDefault();
}

function onDropUpload(event: DragEvent) {
    let vm = events.readAssoc<UploadPage>(event.target)!;
    event.preventDefault();
    let item = event.dataTransfer?.items[0];
    if (item) {
        doUpload(item, vm);
    }
}

function onPasteUpload(event: ClipboardEvent) {
    let vm = events.readAssoc<UploadPage>(event.target)!;
    event.preventDefault();
    let items = event.clipboardData?.files || event.clipboardData?.items;
    if (items) {
        doUpload(items[0], vm);
    }
}

async function onChangeUpload(event: Event) {
    let vm = events.readAssoc<UploadPage>(event.target)!;
    let fileInput = event.currentTarget as HTMLInputElement;
    const files = fileInput.files;
    if (files) {
        doUpload(files[0], vm);
    }
}

class UploadPage {
    uploading: boolean = false;
    dropping: boolean = false;
    error: string = "";
}

export function makeUploadPage(_route: string, _prefix: string) {
    return rpc.asResponse(new UploadPage());
}

export function viewUploadPage(_route: string, _prefix: string, vm: UploadPage): VNode {
    return layout.page(
        <div>
            <div class={clsUploadForm}>
                <div
                    class={clsPasteField}
                    contentEditable
                    {...events.assocAttrs(vm)}
                    onPaste={onPasteUpload}
                    onInput={onInputBlock}
                    onDrop={onDropUpload}
                >
                    {!vm.uploading && "Paste or drop here"}
                    {vm.uploading && "Uploading ..."}
                </div>
                {!vm.uploading && (<>
                    <label class={ui.clsButton}>
                        Click to select a file
                        <input type="file" {...events.assocAttrs(vm)} onChange={onChangeUpload} />
                    </label>
                </>)}
                {vm.error && <div class={clsError}>{vm.error}</div>}
                <div class={clsUploadNotes}>
                    <p>Curent Limitations:</p>
                    <ul>
                    <li>Mobile phones are not supported</li>
                    <li>Annotations are not persisted</li>
                    <li>Uploaded images are not stored long term</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

async function doUpload(fileItem: File | DataTransferItem, vm: UploadPage) {
    if (fileItem.type.indexOf("image") === -1) {
        vm.error = `not an image file: "${fileItem.type}"`;
        return;
    }
    let file: File | null = null;
    if (fileItem instanceof DataTransferItem) {
        file = fileItem.getAsFile();
    } else {
        file = fileItem;
    }
    if (!file) {
        vm.error = "File invalid!";
        return;
    }

    let fd = new FormData();
    fd.set("image", file);

    vm.error = "";
    vm.uploading = true;
    core.scheduleRedraw();
    let [pic, err] = await server.UploadImage(fd);

    if (pic) {
        core.setRoute("/#/image/" + pic.Name);
    } else {
        vm.uploading = false;
        vm.error = `Upload failed: "${err}"`;
        core.scheduleRedraw();
    }
}
