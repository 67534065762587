import * as preact from "preact";

import * as core from "@lib/core";
import * as refs from "@lib/refs";

const INPUT_REF_ATTR = "data-ref";

function encodeRefAttr(ref: refs.Ref): string {
    let objectId = refs.objectId(ref.obj);
    let fieldId = refs.objectId(ref.key);
    return `${objectId}:${fieldId}`;
}

function decodeRefAttr(sref: string): refs.Ref {
    let [objectIdStr, fieldIdStr] = sref.split(":");
    return refs.ref(
        refs.objectById(parseInt(objectIdStr)),
        refs.objectById(parseInt(fieldIdStr)),
    );
}

function getAttr(el: HTMLElement | EventTarget | null, attr: string): string {
    if (el instanceof HTMLElement) {
        return el.getAttribute(attr) ?? "";
    } else {
        return "";
    }
}

export function readNumberAttr(
    el: HTMLElement | EventTarget | null,
    attr: string,
): number {
    if (el instanceof HTMLElement) {
        return parseInt(el.getAttribute(attr) ?? "");
    } else {
        return Number.NaN;
    }
}

export function readObjectRefAttr<T>(
    el: HTMLElement | EventTarget | null,
    attr: string,
): T | null {
    return refs.objectById<T>(readNumberAttr(el, attr));
}

function onInput(event: Event) {
    let target = event.target as HTMLInputElement;
    let ref = decodeRefAttr(getAttr(target, INPUT_REF_ATTR));
    let refValue = refs.get(ref);

    let value: any = target.value;
    if (target.type === "checkbox") {
        value = target.checked;
    }
    if (target.type === "radio") {
        value = target.value;
    }
    if (target.type === "number" || typeof refValue === "number") {
        value = parseInt(value);
    }

    if (ref.obj instanceof Storage) {
        value = JSON.stringify(value);
    }
    refs.set(ref, value);
    core.scheduleRedraw();
}

export type RedrawMode = "regular" | "container" | "off";
export type SpecialType = "rune" | "radio";

export interface InputOptions {
    redraw?: RedrawMode;
    special?: SpecialType;
    initial?: any;
}

export function inputAttrs(ref?: refs.Ref, options: InputOptions = {}): any {
    if (!ref) {
        return {};
    }
    let value = refs.get(ref);
    if (ref.obj instanceof Storage) {
        value = safeJsonParse(value, options.initial);
    }
    if (options?.special == "rune") {
        value = String.fromCodePoint(value);
    }

    let isBoolean = typeof value === "boolean" || options.special === "radio";
    let valueField = isBoolean ? "checked" : "value";

    return {
        [INPUT_REF_ATTR]: encodeRefAttr(ref),
        [valueField]: value,
        onInput: onInput,
    };
}

export function contentEditableAttrs(ref: refs.Ref<string>): any {
    let value = refs.get(ref);
    return {
        [INPUT_REF_ATTR]: encodeRefAttr(ref),
        onInput: onInputContentEditable,
        contentEditable: true,
        dangerouslySetInnerHTML: { __html: value },
    };
}

// content editable caret
type CECaret = {
    activeElement: Element | null;

    // -1 for no selection
    start: number;
    end: number;
};

// given a childNode and childNodeOffset, return an offset relative to the focusNode
function offsetWithinFocusNode(
    focusNode: Node,
    childNode: Node,
    childNodeOffset: number,
): number {
    let currentOffset = 0;
    for (let i = 0; i < focusNode.childNodes.length; i++) {
        let n = focusNode.childNodes[i];
        if (childNode === n) {
            return currentOffset + childNodeOffset;
        } else if (childNode.contains(n)) {
            return (
                currentOffset +
                offsetWithinFocusNode(n, childNode, childNodeOffset)
            );
        } else {
            let t = n.textContent;
            if (t) {
                currentOffset += t.length;
            }
        }
    }
    return -1;
}

function offsetContainerOfCaret(
    focusNode: Node,
    offset: number,
): [Node, number] | null {
    if (focusNode.childNodes.length === 0) {
        return [focusNode, offset];
    }
    let currentOffset = 0;
    for (let i = 0; i < focusNode.childNodes.length; i++) {
        let n = focusNode.childNodes[i];
        let t = n.textContent;
        let nextOffset = currentOffset;
        if (t) {
            nextOffset += t.length;
        }
        if (n.nodeName === "BR") {
            nextOffset++;
        }
        if (nextOffset >= offset) {
            // if node has no child nodes, just return the node itself.
            // if node has child nodes, recurse
            let relativeOffset = offset - currentOffset;
            if (n.childNodes.length > 0) {
                return offsetContainerOfCaret(n, relativeOffset);
            } else {
                return [n, relativeOffset];
            }
        }

        currentOffset = nextOffset;
    }
    return null;
}

function getCaret(): CECaret {
    let sel = getSelection();
    let activeElement = document.activeElement;
    let start = -1;
    let end = -1;

    if (activeElement && sel && sel.rangeCount > 0) {
        let range = sel.getRangeAt(0);
        start = offsetWithinFocusNode(
            activeElement,
            range.startContainer,
            range.startOffset,
        );
        if (range.collapsed) {
            end = start;
        } else {
            end = offsetWithinFocusNode(
                activeElement,
                range.endContainer,
                range.endOffset,
            );
        }
    }

    return {
        activeElement,
        start,
        end,
    };
}

function setCaret(caret: CECaret) {
    if (caret.start == -1 || caret.end == -1) {
        return;
    }
    if (!caret.activeElement) {
        return;
    }
    let sel = window.getSelection();
    if (!sel) {
        console.log("can't set caret; no selection");
        return;
    }
    if (caret.activeElement !== document.activeElement) {
        // (caret.activeElement as HTMLElement).focus();
        return;
    }

    let range = document.createRange();
    let rangeStart = offsetContainerOfCaret(caret.activeElement, caret.start);
    let rangeEnd = offsetContainerOfCaret(caret.activeElement, caret.end);
    if (rangeStart && rangeEnd) {
        range.setStart(...rangeStart);
        range.setEnd(...rangeEnd);
    } else {
        return;
    }

    sel.removeAllRanges();
    sel.addRange(range);
}

function onInputContentEditable(event: InputEvent) {
    const caret = getCaret();
    // console.log("Caret:", caret)

    let currentTarget = event.currentTarget as HTMLElement;
    let content = currentTarget.innerHTML;
    let ref = decodeRefAttr(getAttr(currentTarget, INPUT_REF_ATTR));
    refs.set(ref, content);

    core.scheduleRedraw();

    requestAnimationFrame(() => {
        setCaret(caret);
    });
}

// associated object id
const OBJECT_ID = "assoc-id";

function assocAttrName(key?: string): string {
    if (key) {
        return OBJECT_ID + "-" + key
    } else {
        return OBJECT_ID
    }
}

export function assocAttrs(obj: any, key?: string): any {
    if (!obj) {
        return {};
    }
    let attr = assocAttrName(key)
    return {
        [attr]: refs.objectId(obj),
    };
}

export function readAssoc<T>(
    element: HTMLElement | EventTarget | null,
    key?: string
): T | null {
    let attr = assocAttrName(key)
    return readObjectRefAttr<T>(element, attr);
}

// TODO: support this usecase within inputAttrs
export function radioAttrs<T extends number | string>(
    ref: refs.Ref<T>,
    option: T,
) {
    return {
        [INPUT_REF_ATTR]: encodeRefAttr(ref),
        value: option,
        checked: refs.get(ref) === option,
        onInput: onInput,
    };
}

const TOGGLE_BUTTON_ATTR = "data-toggle-ref";

export function toggleButtonAttrs(r?: refs.Ref<boolean>) {
    if (!r) {
        return {};
    }
    return {
        [TOGGLE_BUTTON_ATTR]: encodeRefAttr(r),
        onClick: onToggleClick,
    };
}

function onToggleClick(event: Event) {
    let ref = decodeRefAttr(getAttr(event.currentTarget, TOGGLE_BUTTON_ATTR));
    refs.set(ref, !refs.get(ref));
    core.scheduleRedraw();
}

// for boolean refs whose purpose is to know about click events
export function consumeBooleanRef(b: refs.Ref<boolean>): boolean {
    let result = refs.get(b);
    if (result) {
        refs.set(b, false);
    }
    return result;
}

function safeJsonParse(
    raw: string | undefined | null,
    fallback?: unknown,
): unknown {
    if (!raw) {
        return fallback;
    }
    try {
        return JSON.parse(raw);
    } catch (e) {
        return fallback;
    }
}

export const ELEMENT_REF_ATTR = "data-element-obj";

export function elementRefAttrs(r?: refs.Ref<HTMLElement | null>): any {
    if (!r) {
        return {};
    }
    return {
        [ELEMENT_REF_ATTR]: encodeRefAttr(r),
        "listen-create": true,
        "listen-mutate": true,
        oncreate: eventSetRef,
        onmutate: eventSetRef,
    };
}

export function eventSetRef(event: CustomEvent) {
    let ref = decodeRefAttr(getAttr(event.currentTarget, ELEMENT_REF_ATTR));
    refs.set(ref, event.currentTarget);
}

const HOVER_REF_ATTR = "hover-object";

function onHover(event: Event) {
    let ref = decodeRefAttr(getAttr(event.currentTarget, HOVER_REF_ATTR));
    if (event.type === "mouseenter") {
        refs.set(ref, true);
    } else if (event.type === "mouseleave") {
        refs.set(ref, false);
    }
    core.scheduleRedraw();
}

export function trackHoverAttrs(r?: refs.Ref<boolean>) {
    if (!r) {
        return {};
    }
    return {
        [HOVER_REF_ATTR]: encodeRefAttr(r),
        onMouseEnter: onHover,
        onMouseLeave: onHover,
    };
}

const RENDER_FN_ATTR = "render-fn";
const RENDER_PARAM_ATTR = "render-param";

export function containerProps<P>(fn: (p: P) => preact.VNode, p: P) {
    return {
        [RENDER_FN_ATTR]: refs.objectId(fn),
        [RENDER_PARAM_ATTR]: refs.objectId(p),
    };
}

export function hasAttrs(el: HTMLElement, ...attrs: string[]): boolean {
    for (let attr of attrs) {
        if (!el.hasAttribute(attr)) {
            return false;
        }
    }
    return true;
}

export function parentElementWithAttrs(
    el: HTMLElement | null,
    ...attrs: string[]
): HTMLElement | null {
    while (el && el !== core.rootDiv) {
        if (hasAttrs(el, ...attrs)) {
            return el;
        } else {
            el = el.parentElement;
        }
    }
    return el;
}

export function parentElementWithAttr(
    el: HTMLElement | null,
    attr: string,
): HTMLElement | null {
    while (el) {
        if (el.hasAttribute(attr)) {
            return el;
        } else if (el === core.rootDiv) {
            return null;
        } else {
            el = el.parentElement;
        }
    }
    return null;
}
