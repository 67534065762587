export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export interface Rect extends Point, Size {}

export interface Frame {
    top: number;
    left: number;
    width: number;
    height: number;
}

export interface Placement {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    width?: number;
    height?: number;
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
}

export interface SizeConstraints {
    maxWidth?: number;
    maxHeight?: number;
}

export function zeroSize(): Size {
    return { width: 0, height: 0 };
}
export function zeroPoint(): Point {
    return { x: 0, y: 0 };
}

export function zeroFrame(): Frame {
    return {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
    };
}

export function zeroRect(): Rect {
    return {
        ...zeroPoint(),
        ...zeroSize(),
    };
}

export function constrainedSize(size: Size, cons: SizeConstraints): Size {
    let width = size.width;
    let height = size.height;

    if (cons.maxHeight !== undefined && height > cons.maxHeight) {
        let factor = cons.maxHeight / height;
        height = cons.maxHeight;
        width = Math.floor(width * factor);
        // console.log({width, height});
    }
    if (cons.maxWidth !== undefined && width > cons.maxWidth) {
        let factor = cons.maxWidth / width;
        width = cons.maxWidth;
        height = Math.floor(height * factor);
        // console.log({width, height});
    }
    return { width, height };
}

export function getWindowSize(): Size {
    return {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
    };
}

export function constrainedFrame(position: Point, size: Size, windowSize: Size): Frame {
    if (position.x + size.width > windowSize.width) {
        position.x = windowSize.width - size.width;
        if (position.x < 0) {
            position.x = 0;
        }
    }
    if (position.y + size.height > windowSize.height) {
        position.y = windowSize.height - size.height;
        if (position.y < 0) {
            position.y = 0;
        }
    }
    let frame: Frame = {
        top: position.y,
        left: position.x,
        width: size.width,
        height: size.height,
    };
    return frame;
}

export function pointSub(p0: Point, p1: Point): Point {
    return {
        x: p1.x - p0.x,
        y: p1.y - p0.y,
    };
}

export function pointAdd(p0: Point, p1: Point): Point {
    return {
        x: p1.x + p0.x,
        y: p1.y + p0.y,
    };
}

export type Line = [Point, Point];

export function vectorLength(vec: Point): number {
    return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
}

export function pointDist(pt0: Point, pt1: Point): number {
    return vectorLength(pointSub(pt1, pt0))
}

export function lineLength(line: Line): number {
    return pointDist(...line)
}

export function normalVector(pt0: Point, pt1: Point): Point {
    let vec = pointSub(pt1, pt0);
    let normal: Point = { x: -vec.y, y: vec.x }; // surprisingly simple but correct!
    let length = vectorLength(normal);
    if (length === 0) {
        return zeroPoint();
    }
    normal.x /= length;
    normal.y /= length;
    return normal;
}

export function parallelTransition(line: Line, distance: number): Line {
    let [pt0, pt1] = line;
    let normal = normalVector(pt0, pt1);
    normal.x *= distance;
    normal.y *= distance;
    return [pointAdd(pt0, normal), pointAdd(pt1, normal)];
}

// generated by ChatGPT
export function distanceFromPointToLine(p: Point, line: Line): number {
    const [a, b] = line;
    const lineLength = Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
    if (lineLength === 0) return Math.sqrt((p.x - a.x) ** 2 + (p.y - a.y) ** 2);
    const t = ((p.x - a.x) * (b.x - a.x) + (p.y - a.y) * (b.y - a.y)) / (lineLength ** 2);
    const closestPoint = t < 0 ? a : t > 1 ? b : { x: a.x + t * (b.x - a.x), y: a.y + t * (b.y - a.y) };
    return Math.sqrt((p.x - closestPoint.x) ** 2 + (p.y - closestPoint.y) ** 2);
}

// Interpolates or extrapolates a point on a line defined by a segment, based on factor t.
// Generated by ChatGPT
export function interpolateLine(line: Line, t: number): Point {
    const [pt0, pt1] = line;

    // Linearly interpolate or extrapolate the x and y coordinates
    const x = pt0.x + (pt1.x - pt0.x) * t;
    const y = pt0.y + (pt1.y - pt0.y) * t;

    return { x, y };
}


// Provided by ChatGPT (including the comments!)
//
/**
 * Checks if two rectangles intersect using the Separating Axis Theorem (SAT).
 * According to SAT, two convex shapes are separated if and only if there exists an axis
 * along which the shapes do not overlap. For axis-aligned rectangles, this simplifies to
 * checking overlap along the x and y axes.
 *
 * @param rect1 - The first rectangle.
 * @param rect2 - The second rectangle.
 * @returns true if the rectangles intersect, false otherwise.
 */
export function rectsIntersect(rect1: Rect, rect2: Rect): boolean {
    return (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
    );
}

// some math
export function pointScale(p: Point, f: number): Point {
    return {
        x: p.x * f,
        y: p.y * f,
    };
}

export function frameScale(r: Frame, factor: number): Frame {
    return {
        top: Math.round(r.top * factor),
        left: Math.round(r.left * factor),
        width: Math.round(r.width * factor),
        height: Math.round(r.height * factor),
    };
}

// returns negative number if point is inside rect, and positive if outside
// this is basically (almost) a signed distance function that does not respect the corner radius
export function distanceToRect(point: Point, rect: Rect): number {
    let half_width_x = rect.width / 2;
    let half_width_y = rect.height / 2;

    let center_x = rect.x + half_width_x;
    let center_y = rect.y + half_width_y;

    let dist_x = Math.abs(point.x - center_x) - half_width_x;
    let dist_y = Math.abs(point.y - center_y) - half_width_y;

    return Math.max(dist_x, dist_y);
}
