import * as preact from "preact";
import { VNode } from "preact";

import * as css from "./css";
import * as refs from "./refs";
import * as cache from "./cache";
import * as events from "./events";

export const maroonDark = "hsl(5, 40%, 30%)";
export const maroon = "hsl(5, 45%, 40%)";
export const maroonMid = "hsl(5, 45%, 70%)";
export const maroonLight = "hsl(5, 45%, 94%)";

export const olive = "#435B33";
export const oliveLight = "#F4F8F2";

export const azure = "#2D4E4E";
export const azureLight = "#E5F0F0";

export const blue = "hsl(240, 50%, 50%)";

export function clsCached(props: css.RuleDefinition): string {
    return cache.get([clsCached, props], css.cls, "cls-gen", props);
}

export function classes(...names: Array<string | false | undefined>): string {
    let stringNames: string[] = [];
    for (let name of names) {
        if (typeof name === "string") stringNames.push(name);
    }

    return stringNames.join(" ");
}

export function Panel(props: Panel.Props) {
    let clsBody = props.padded ? Panel.cls.bodyPadded : Panel.cls.body;
    return (
        <div class={Panel.cls.panel}>
            <div class={Panel.cls.title}>
                <div class={Panel.cls.jpTitle}>{props.japanese}</div>
                <div class={Panel.cls.arTitle}>{props.arabic}</div>
            </div>
            <div class={clsBody}>{props.children}</div>
        </div>
    );
}

export namespace Panel {
    export type Props = {
        arabic: string;
        japanese: string;
        padded?: boolean;
        children: preact.ComponentChildren;
    };

    export namespace cls {
        export const panel = css.cls("panel", {
            borderRadius: "10px",
            background: maroon,
            padding: "3px",
        });
        export const title = css.cls("panel-title", {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 10px",
            marginBottom: "4px",
        });
        const cssTitlePart: css.RuleDefinition = {
            height: "26px",
            lineHeight: "26px",
            fontSize: "18px",
            width: "max-content",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        };
        export const jpTitle = css.cls("jp-title", {
            ...cssTitlePart,
            direction: "ltr",
            background: "white",
            color: maroon,
            borderRadius: "16px",
            padding: "2px 10px",
        });
        export const arTitle = css.cls("ar-title", {
            ...cssTitlePart,
            direction: "rtl",
            color: "white",
        });

        const cssBody: css.RuleDefinition = {
            borderRadius: "4px",
            marginBottom: "4px",
            overflow: "hidden",

            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            background: maroon,
            '> *': {
                background: "white"
            }
        };
        export const body = css.cls("body", {
            ...cssBody,
            padding: "0px",
        });
        export const bodyPadded = css.cls("body-padded", {
            ...cssBody,
            padding: "4px 8px",
        });
    }
}

export const clsLink = css.cls("link", {
    color: maroon,
    textDecoration: "none",
    cursor: "pointer",
    "img[src*=svg]": {
        filter: "hue-rotate(5deg) saturate(90%) brightness(80%) "
    }
});

// ----------------------------------------------------------------------------
// metadata box

const clsMetadataBox = css.cls("metadata-box", {
    direction: "rtl",
    background: "whitesmoke",
    borderRadius: "10px",
    border: "1px solid gainsboro",
    "> div.title": {
        margin: "0px",
        padding: "10px 16px 0px",
        borderBottom: "1px solid gainsboro",
    },
    "> div.content": {
        padding: "10px 16px",
    }
})

type TextElement = "p" | "span" | "strong" | "h1" | "h2" | "h3" | "h4"

export function auto_dir_el(el: TextElement, text: string) {
    return preact.h(el, {dir: textDir(text)}, text)
}

export function metadataBox(params: { title: VNode, body: VNode }) {
    return <div class={clsMetadataBox}>
        <div class="title">{params.title}</div>
        <div class="content">{params.body}</div>
    </div>
}

export function metadataBoxSimple(title: string, body: string) {
    return metadataBox({
        title: <strong>{title}</strong>,
        body: <p>{body}</p>,
    })
}

// ----------------------------------------------------------------------------
// basic page layout for desktop two columns with 5 : 3 proportions

export const clsContentLayoutWide = css.cls("content-layout", {
    margin: "20px 10px",

    display: "flex",
    flexDirection: "row",
    gap: "20px",
    "> div": {
        display: "flex",
        flexDirection: "column",
        gap: "20px",

        width: "35%",
        ":first-child": {
            flexGrow: 1
        }
    }
})

export const clsContentLayoutNarrow = css.cls("content-layout-n", {
    margin: "20px 10px",

    display: "flex",
    flexDirection: "column",
    gap: "20px",
})

// ----------------------------------------------------------------------------

type LayoutOptions = Pick<
    css.RuleDefinition,
    | "display"
    | "flexDirection"
    | "flexWrap"
    | "alignItems"
    | "justifyContent"
    | "gap"
    | "padding"
    | "margin"
    | "flexGrow"
    | "width"
    | "height"
    | "maxWidth"
    | "position"
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "overflow"
    | "borderRadius"
>;

export function clsLayout(options: LayoutOptions): string {
    // TODO: perhaps we can do better by producing a key from the list of all values, e.g.
    // hashAny(options.flexDirection, options.flexFlow, .... explicitly list all values)
    return cache.get([clsLayout, options], css.cls, "layout", options);
}

type ColorsOptions = Pick<
    css.RuleDefinition,
    "color" | "backgroundColor" | "borderColor"
>;

export function clsColors(options: ColorsOptions): string {
    return cache.get([clsColors, options], css.cls, "color", options);
}

type FontOptions = Pick<
    css.RuleDefinition,
    "fontFamily" | "fontSize" | "fontWeight" | "textDecoration"
>;

export function clsFont(options: FontOptions): string {
    return cache.get([clsFont, options], css.cls, "font", options);
}

type ElementOptions = {
    layout?: LayoutOptions;
    font?: FontOptions;
    colors?: ColorsOptions;

    name?: string; // element name; defaults to div

    inputTarget?: refs.Ref;
    inputOptions?: events.InputOptions;

    assoc?: any;

    elementRef?: refs.Ref<HTMLElement | null>;
    hoverRef?: refs.Ref<boolean>;
    toggleRef?: refs.Ref<boolean>;

    children: preact.ComponentChildren;

    // generic attributes we want to use from the html attributes ...
    onSubmit?: preact.JSX.HTMLAttributes["onSubmit"];
};

export function element(options: ElementOptions): VNode {
    return preact.h(options.name ?? "div", {
        class: classes(
            options.layout && clsLayout(options.layout),
            options.font && clsFont(options.font),
            options.colors && clsColors(options.colors),
        ),

        ...events.inputAttrs(options.inputTarget, options.inputOptions),
        ...events.trackHoverAttrs(options.hoverRef),
        ...events.elementRefAttrs(options.elementRef),
        ...events.toggleButtonAttrs(options.toggleRef),
        ...events.assocAttrs(options.assoc),

        onSubmit: options.onSubmit,

        children: options.children,
    });
}

// ----------------------------------------------------------------------------

export function isArabic(c: string): boolean {
    return c >= "\u0600" && c <= "\u06ff"; // standard arabic forms
}

export function isRomaji(c: string): boolean {
    return (c >= "a" && c <= "z") || (c >= "A" && c <= "Z");
}

export function isKanji(c: string): boolean {
    return (c >= "\u3400" && c <= "\u4dbf") || (c >= "\u4e00" && c <= "\u9fff");
}

export function isKatakana(c: string): boolean {
    return c >= "\u30a1" && c <= "\u30f6";
}

export function isHiragana(c: string): boolean {
    return c >= "\u3041" && c <= "\u3096";
}

export function isJapanese(c: string): boolean {
    return isKanji(c) || isKatakana(c) || isHiragana(c);
}

export function textDir(t: string): Dir | undefined {
    for (const c of t) {
        if (isArabic(c)) {
            return "rtl";
        }
        if (isJapanese(c) || isRomaji(c)) {
            return "ltr";
        }
    }
    return undefined;
}

// ----------------------------------------------------------------------------

export type Dir = "ltr" | "rtl";

interface TextInputProps {
    type?: string;
    label: string;
    ref: refs.Ref<string> | refs.Ref<number>;
    placeholder?: string;
    description?: string;
    autoFocus?: boolean;
    dir?: Dir;
    textAlign?: TextAlign;
    fontSize?: number;

    special?: events.InputOptions["special"];

    labelColor?: string;
    descriptionColor?: string;

    labelDir?: Dir;

    onblur?: Function;
    onkeypress?: Function;

    width?: number;

    rows?: number; // only for textarea

    // TODO: should we add a `multiline` flag to use textarea
    // instead of creating another separate function for textarea?
}

type CSSProperties = preact.JSX.CSSProperties;

function style_inputbox(props: TextInputProps): CSSProperties {
    return {
        direction: props.dir,
        fontSize: props.fontSize,
        textAlign: props.textAlign,
        width: props.width,
        boxSizing: "content-box",
    };
}

// TODO: do something to restore autoTextDir
// we are removing it now because we are cleaning up the atoms and refs bullshit

const clsLabel = css.cls("label", {
    display: "flex",
    flexDirection: "column",
    "> span": {
        fontSize: "14px",
    },
    "> input, > textarea": {
        flex: "1",
    },
})

const cssTextInputBase: css.RuleDefinition = {
    fontSize: "18px",
    boxShadow: "1px 2px 3px -1px hsl(240, 30%, 78%) inset",
    background: "linear-gradient(180deg, hsl(240, 10%, 96%), hsl(240, 20%, 100%))",
}

// for both input and text area
export const clsInput = css.cls("text_input", {
    padding: "4px 8px",
    borderRadius: "2px",
    border: "1px solid grey",

    color: "hsl(0, 0%, 20%)",
    /* textShadow: "1px 1px 1px white", */

    ...cssTextInputBase,
})

export function inputBox(params: TextInputProps): VNode {
    let type = params.type ?? "text";

    const inputEl = (
        <input
            type={type}
            class={clsInput}
            autoFocus={params.autoFocus}
            style={style_inputbox(params)}
            onblur={params.onblur}
            onkeypress={params.onkeypress}
            placeholder={params.placeholder}
            {...events.inputAttrs(params.ref, { special: params.special })}
        />
    );
    if (params.label === "") {
        return inputEl;
    }
    const labelColor = params.labelColor ?? "hsl(0, 0%, 20%)";
    const descriptionColor = params.descriptionColor ?? "hsl(0, 0%, 54%)";
    return (
        <label class={clsLabel} dir={params.dir}>
            <span style={{ color: labelColor, direction: params.labelDir }}>
                {params.label}
            </span>
            {inputEl}
            <span style={{ color: descriptionColor, fontSize: "12px" }}>
                {params.description}
            </span>
        </label>
    );
}

export const clsTextarea = css.cls("textarea", {
    fontFamily: "serif",
    resize: "vertical",

    ...cssTextInputBase,
})

export function textArea(params: TextInputProps): VNode {
    const inputEl = (
        <textarea
            class={clsTextarea}
            autoFocus={params.autoFocus}
            placeholder={params.placeholder}
            style={style_inputbox(params)}
            {...events.inputAttrs(params.ref)}
            rows={params.rows}
        />
    );
    if (!params.label) {
        return inputEl;
    }
    return (
        <label class="label" dir={params.dir}>
            <span>{params.label}</span>
            {inputEl}
        </label>
    );
}

export interface SelectOption<V> {
    value: V;
    label: string;
    disabled?: boolean;
}

export interface SelectProps<V> {
    options: SelectOption<V>[];
    refId: refs.Ref<V>
    isNumber?: boolean;
    disabled?: boolean;
    label?: string;
    labelColor?: string;
}

const cssDisabled: css.RuleDefinition = {
    filter: "grayscale(1) opacity(0.5)",
    pointerEvents: "none",
    userSelect: "none",
}

const clsSelect = css.cls("select", {
    fontSize: "18px",
    padding: "4px",
    borderRadius: "4px",
    border: "1px solid grey",
    background: "linear-gradient(white, hsl(0, 0%, 90%))",

    minWidth: "0px", // prevents element from expanding its parent ..
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    ":disabled": { ...cssDisabled },
})

export function select<V extends string | number>(params: SelectProps<V>) {
    const select_el = (
        <select class={clsSelect} value={refs.get(params.refId)} disabled={params.disabled} {...events.inputAttrs(params.refId)}>
            {params.options.map((option) => (
                <option value={option.value} label={option.label} disabled={option.disabled} />
            ))}
        </select>
    );
    if (!params.label) {
        return select_el;
    }

    const labelColor = params.labelColor ?? "hsl(0, 0%, 20%)";

    return (
        <label class="label">
            <span style={{ color: labelColor }}>{params.label}</span>
            {select_el}
        </label>
    );
}

export enum MainAlign {
    Start,
    Center,
    End,
    SpaceAround,
    SpaceBetween,
    SpaceEvenly,
}

function value_align(align: MainAlign): string {
    switch (align) {
        case MainAlign.Start:
            return "flex-start";
        case MainAlign.Center:
            return "center";
        case MainAlign.End:
            return "flex-end";
        case MainAlign.SpaceAround:
            return "space-around";
        case MainAlign.SpaceBetween:
            return "space-between";
        case MainAlign.SpaceEvenly:
            return "space-evenly";
    }
}

export enum CrossAlign {
    Start,
    Center,
    End,
    Stretch,
    Baseline,
}

function value_cross_align(align: CrossAlign): string {
    switch (align) {
        case CrossAlign.Start:
            return "flex-start";
        case CrossAlign.Center:
            return "center";
        case CrossAlign.End:
            return "flex-end";
        case CrossAlign.Stretch:
            return "stretch";
        case CrossAlign.Baseline:
            return "baseline";
    }
}

export interface RowProps {
    align?: MainAlign;
    crossAlign?: CrossAlign;
    wrap?: boolean;
    dir?: Dir;
    gap?: string;

    children: preact.ComponentChildren[];
}

function project<P, R>(p: P | undefined, fn: (p: P) => R): R | undefined {
    return p ? fn(p) : undefined;
}

export const cssRow: css.RuleDefinition = {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
}

export const clsRow = css.cls("row", {
    ...cssRow,
    gap: "20px"
});

export interface ColumnProps {
    align?: MainAlign;
    crossAlign?: CrossAlign;

    children: preact.ComponentChildren[];
}

function style_column(props: ColumnProps): CSSProperties {
    return {
        justifyContent: project(props.align, value_align),
        alignItems: project(props.crossAlign, value_cross_align),
    };
}

export const clsColumn = css.cls("col", {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
});

type TextAlign = "left" | "center" | "right";

const clsSectionHeader = css.cls("section-header", {
    background: "hsl(200, 75%, 95%)",
    padding: "10px",
    fontSize: "18px",
    fontWeight: 700,
});

export function sectionHeader(title: string): VNode {
    return (
        <div class={clsSectionHeader} dir={textDir(title)}>
            <span>{title}</span>
        </div>
    );
}

export function vseparator(size: number, color: string): VNode {
    const style = `width: ${size}px; background: ${color}; height: 100%`;
    return preact.h("div", { style: style });
}

export function separator(size: number, color: string): VNode {
    const style = `height: ${size}px; background: ${color}; width: 100%`;
    return preact.h("div", { style: style });
}

export function vspacer(size: number): VNode {
    const style = `display: block; height: ${size}px;`;
    return <div style={style} />;
}

export function hspacer(size: number): VNode {
    const style = `display: block; width: ${size}px;`;
    return <div style={style} />;
}

export function inlineSpacer(size: number): VNode {
    return <div style={{ display: "inline-block", width: size }} />;
}

const clsImg = css.cls("img", {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
});

export function image(url: string): VNode {
    return preact.h("img", { class: clsImg, src: url });
}

export const cssButtonStdSize: css.RuleDefinition = {
    fontSize: "18px",
    padding: "4px 15px",
};

export const cssButtonCtrlSize: css.RuleDefinition = {
    fontSize: "12px",
    padding: "2px 4px",
};

export const cssWidgetBase: css.RuleDefinition = {
    border: "1px solid gray",
    boxShadow: "0px 0px 2px 0px hsla(0, 0%, 0%, 0.24)",
}

function makeButtonCSS(
    hue: number,
    saturation: number,
    light: number,
): css.RuleDefinition {
    return {
        ...cssWidgetBase,
        cursor: "default",
        borderRadius: "4px",
        background: `linear-gradient(hsl(${hue}, ${saturation}%, ${light}%), hsl(${hue}, ${saturation}%, ${
            light * 0.9
        }%))`,
        color: light > 80 ? "black" : "white",
        boxShadow: "0px 2px 1px -1px hsla(0, 0%, 0%, 0.24)",
        textShadow:
            light > 80
                ? "0px 1px 0px white"
                : "0px -1px 0px hsla(0, 0%, 20%, 0.7)",
        transform: "translateY(-1px)",
        ":active": {
            boxShadow: "0px 1px 1px -1px hsla(0, 0%, 0%, 0.24)",
            transform: "translateY(0px)",
        },
        ":disabled": { ...cssDisabled },
    };
}

export const cssBasicButton = makeButtonCSS(0, 0, 100);

export const clsCtrlBtn = css.cls("btn-ctrl", {
    ...cssBasicButton,
    ...cssButtonCtrlSize,
});

export const clsButton = css.cls("btn", {
    ...cssBasicButton,
    ...cssButtonStdSize,
});

export const clsButtonDanger = css.cls("btn-danger", {
    ...makeButtonCSS(0, 80, 60),
    ...cssButtonStdSize,
});

const cssLinkButton: css.RuleDefinition = {
    ...cssBasicButton,
    ...cssButtonStdSize,
    textDecoration: "none",
    "img": {
        marginLeft: "10px",
    }
};

export const clsButtonLink = css.cls("btn-link", {
    ...cssLinkButton,
});

export const clsButtonLinkDisabled = css.cls("btn-link", {
    ...cssLinkButton,
    opacity: 0.5,
    pointerEvents: "none",
});

type ToggleButtonParams = {
    icon?: string;
    label: string;
    target: refs.Ref<boolean>;
};

export function ToggleButton(params: ToggleButtonParams) {
    return (
        <button class={clsCtrlBtn} {...events.toggleButtonAttrs(params.target)}>
            {showIcon(params.icon)} {params.label}
        </button>
    );
}

export function showIcon(icon: string = "", height: number = 20): VNode {
    if (icon) {
        return (
            <img
                src={"images/icons/" + icon}
                height={height}
                style={{ verticalAlign: "middle" }}
            />
        );
    } else {
        return <></>;
    }
}

export interface ButtonProps {
    label: string;
    onclick?: Function;
    disabled?: boolean;
    submit?: boolean;
    class?: string;

    layout?: LayoutOptions;
}

export function button(params: ButtonProps): VNode {
    let type = "button";
    if (params.submit) {
        type = "submit";
    }
    const dir = textDir(params.label);
    return preact.h(
        "button",
        {
            type: type,
            class: classes(
                params.class ?? clsButton,
                params.layout && clsLayout(params.layout),
            ),
            onclick: params.onclick,
            disabled: params.disabled,
            dir: dir,
        },
        params.label,
    );
}

export function buttonLink(label: string, href: string | undefined, icon?: string): VNode {
    let cls = href ? clsButtonLink : clsButtonLinkDisabled;
    return (
        <a class={cls} href={href}>
            {label}
            {icon && showIcon(icon)}
        </a>
    );
}

interface FormProps {
    onsubmit: Function;
    node: VNode;
}

const clsForm = css.cls("form", {
    width: "100%"
})

export function form(props: FormProps): VNode {
    return preact.h(
        "form",
        {
            class: clsForm,
            onsubmit: props.onsubmit,
        },
        [props.node],
    );
}

export function icon_image_path(name: string): string {
    return `/images/icons/${name}.png`;
}

// ---------

/**
 * Generated by ChatGPT
 *
 * @param text - The text whose height needs to be measured.
 * @param fontSize - Font size in pixels.
 * @param fontFamily - Font family.
 * @param containerWidth - Container width in pixels.
 * @returns - Measured height of the text fragment in pixels.
 */
function _measureTextHeight(
    text: string,
    fontSize: number,
    fontFamily: string,
    containerWidth: number,
): number {
    const element = document.createElement("div");

    // Set styles
    element.style.position = "absolute";
    element.style.left = "-9999px";
    element.style.top = "-9999px";
    element.style.width = `${containerWidth}px`;
    element.style.fontFamily = fontFamily;
    element.style.fontSize = `${fontSize}px`;
    element.style.whiteSpace = "wrap";

    element.innerText = text;

    document.body.appendChild(element);

    // Measure
    const height = element.clientHeight;

    // Cleanup
    document.body.removeChild(element);

    return height;
}

export function measureTextHeight(
    text: string,
    fontSize: number,
    fontFamily: string,
    containerWidth: number,
): number {
    return cache.get(
        [measureTextHeight, text, fontSize, fontFamily, containerWidth],
        _measureTextHeight,
        text,
        fontSize,
        fontFamily,
        containerWidth,
    );
}
