import * as rpc from "@lib/rpc"

export interface RegisterRequest {
    Name: string
    Password: string
    Email: string
}

export interface SessionInfoResponse {
    Token: string
    User: User
}

export interface LoginRequest {
    Name: string
    Password: string
}

export interface Empty {
}

export interface VerificationCodeInput {
    Code: string
}

export interface User {
    Id: number
    Name: string
    Email: string
    IsAdmin: boolean
    EmailVerified: boolean
}

export async function UserRegister(data: RegisterRequest): Promise<rpc.Response<SessionInfoResponse>> {
    return await rpc.call<SessionInfoResponse>('UserRegister', JSON.stringify(data));
}

export async function UserLogin(data: LoginRequest): Promise<rpc.Response<SessionInfoResponse>> {
    return await rpc.call<SessionInfoResponse>('UserLogin', JSON.stringify(data));
}

export async function UserLogout(data: Empty): Promise<rpc.Response<Empty>> {
    return await rpc.call<Empty>('UserLogout', JSON.stringify(data));
}

export async function UserTokenVerify(data: Empty): Promise<rpc.Response<SessionInfoResponse>> {
    return await rpc.call<SessionInfoResponse>('UserTokenVerify', JSON.stringify(data));
}

export async function RequestVerificationCode(data: Empty): Promise<rpc.Response<Empty>> {
    return await rpc.call<Empty>('RequestVerificationCode', JSON.stringify(data));
}

export async function CheckVerificationCode(data: VerificationCodeInput): Promise<rpc.Response<Empty>> {
    return await rpc.call<Empty>('CheckVerificationCode', JSON.stringify(data));
}

