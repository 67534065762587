import * as core from "@lib/core";

import * as auth from "@auth/auth";
import * as auth_forms from "@auth/forms";

import * as server from "./server";

import * as landing from "./landing";
import * as upload from "./upload";
import * as image from "./image";
import * as layout from "./layout";

function authPageView(_route: string, _prefix: string, data: auth_forms.LoginForm) {
    return layout.page(auth_forms.loginFormLayout(data));
}

// ============================================================================
// Init Routes
// ============================================================================

async function main() {
    await auth.validateAuthToken();

    core.initRoutes([
        auth_forms.routeEntry(authPageView),
        core.routeEntry("/image", image.makeImagePage, image.viewImagePage),
        core.routeEntry("/upload", upload.makeUploadPage, upload.viewUploadPage),
        core.routeEntry("/", landing.makeLandingPage, landing.viewLandingPage),
    ]);
}

main();

// @ts-ignore
window.server = server;
