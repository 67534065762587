import * as preact from "preact";
import { VNode } from "preact";

import * as refs from "@lib/refs";
import * as core from "@lib/core";
import * as rpc from "@lib/rpc";
import * as events from "@lib/events";
import * as utils from "@lib/utils";
import * as ui from "@lib/ui";

import * as server from "@auth/server";
import * as auth from "@auth/auth";

const KLoginRedirectUrl = "loginRedirect";

export const PAGE_ROUTE = "/auth";

let labelUsername: core.LocalizedTextMap = [
    [core.EN, "Username"],
    [core.AR, "اسم المستخدم"],
]

let labelPassword: core.LocalizedTextMap = [
    [core.EN, "Password"],
    [core.AR, "كلمة المرور"],
]

let labelPasswordConfirm: core.LocalizedTextMap = [
    [core.EN, "Confirm Password"],
    [core.AR, "تاكيد كلمة المرور"],
]

let labelEmail: core.LocalizedTextMap = [
    [core.EN, "Email Address"],
    [core.AR, "البريد الالكتروني"],
]

let labelTitleLogin: core.LocalizedTextMap = [
    [core.EN, "Login to your account"],
    [core.AR, "الولوج الى حساب المستخدم"],
]

let labelTitleSignup: core.LocalizedTextMap = [
    [core.EN, "Signup for a new account"],
    [core.AR, "تسجيل حساب مستخدم جديد"]
]

let labelSubmit: core.LocalizedTextMap = [
    [core.EN, "Submit"],
    [core.AR,  "إرسال"],
]

// sets the redirect url so that we go back to the page we were on after we login
// the only exception is if the page we came from was already the auth page!
function onAuthLinkClick() {
    let route = core.getRouteParsed();
    if (route.pathname !== PAGE_ROUTE) {
        sessionStorage.setItem(KLoginRedirectUrl, core.getRoute());
    }
}

function consumeLoginRedirectUrl(): string {
    const value = sessionStorage.getItem(KLoginRedirectUrl) ?? "/";
    sessionStorage.removeItem(KLoginRedirectUrl);
    return value;
}

export function authLink(type: LoginFormType, label: string): VNode {
    let route = "/auth?type=" + type;
    return <a href={core.href(route)} onClick={onAuthLinkClick}>{label}</a>
}

export type LoginFormType = string;
export const TypeLogin = "login";
export const TypeSignup = "signup";
export const TypeForgot = "forgot_password";
export const TypeReset = "reset_password";

export type LoginForm = {
    username: string;
    password: string;
    passwordConfirm: string;
    email: string;
    type: LoginFormType;
    error: string; // from the server
    sending: boolean;
};

export function makeLoginFormData(type: LoginFormType): LoginForm {
    return {
        username: "",
        password: "",
        passwordConfirm: "",
        email: "",
        error: "",
        sending: false,
        type: type,
    };
}

function onSubmit(event: Event) {
    event.preventDefault();
    let form = events.readAssoc<LoginForm>(event.target);
    if (!form) {
        console.log("invalid form submit handler");
        return;
    }

    loginFormSubmit(form);
}

export async function loginFormSubmit(form: LoginForm) {
    form.sending = true;
    core.scheduleRedraw();
    let sessionResponse: server.SessionInfoResponse | null = null;
    let error = "";

    if (form.type === TypeLogin) {
        [sessionResponse, error] = await server.UserLogin({
            Name: form.username,
            Password: form.password,
        });
    }
    if (form.type === TypeSignup) {
        [sessionResponse, error] = await server.UserRegister({
            Name: form.username,
            Password: form.password,
            Email: form.email,
        });
    }

    form.sending = false;
    core.scheduleRedraw();

    if (sessionResponse) {
        auth.setSessionInfo(sessionResponse);
        core.replaceRoute(consumeLoginRedirectUrl());
    } else {
        form.error = error;
    }
}

export function loginFormLayout(form: LoginForm): VNode {
    let showUsername = false;
    let showPassword = false;
    let showPasswordConfirm = false;
    let showEmail = false;
    let title = "";

    switch (form.type) {
        case TypeLogin:
            showUsername = true;
            showPassword = true;
            title = core.selectLocalizedText(labelTitleLogin);
            break;
        case TypeSignup:
            showUsername = true;
            showPassword = true;
            showPasswordConfirm = true;
            showEmail = true;
            title = core.selectLocalizedText(labelTitleSignup);
            break;
        case TypeReset:
            showPassword = true;
            showPasswordConfirm = true;
            break;
        case TypeForgot:
            showEmail = true;
            break;
    }

    return ui.element({
        name: "form",
        onSubmit: onSubmit,
        assoc: form,
        layout: {
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            maxWidth: "600px",
        },
        children: [
            title &&
                ui.element({
                    name: "p",
                    font: { fontSize: "20px", fontWeight: "bold" },
                    children: [title],
                }),
            showUsername &&
                ui.inputBox({
                    label: core.selectLocalizedText(labelUsername),
                    ref: refs.ref(form, "username"),
                    dir: "ltr",
                }),
            showPassword &&
                ui.inputBox({
                    label: core.selectLocalizedText(labelPassword),
                    ref: refs.ref(form, "password"),
                    dir: "ltr",
                    type: "password",
                }),
            showPasswordConfirm &&
                ui.inputBox({
                    label: core.selectLocalizedText(labelPasswordConfirm),
                    ref: refs.ref(form, "passwordConfirm"),
                    dir: "ltr",
                    type: "password",
                }),
            showEmail &&
                ui.inputBox({
                    label: core.selectLocalizedText(labelEmail),
                    ref: refs.ref(form, "email"),
                    dir: "ltr",
                }),
            ui.button({
                disabled: form.sending,
                submit: true,
                label: core.selectLocalizedText(labelSubmit),
                // onclick: onSubmit,
                layout: { width: "120px", margin: "20px 0 0 0 " },
            }),
            form.error &&
                ui.element({
                    name: "p",
                    colors: { color: "red" },
                    children: form.error,
                }),
        ],
    });
}

export function authFetch(
    route: string,
    prefix: string,
): Promise<rpc.Response<LoginForm>> {
    const queryString = utils.removePrefix(route, prefix);
    const params = new URLSearchParams(queryString);
    let type = params.get("type") ?? "login";
    return rpc.asResponse(makeLoginFormData(type));
}

type LoginPageView = core.RouteEntry<LoginForm>["view"];

function makeAuthView(viewFn: LoginPageView) {
    return function (route: string, prefix: string, data: LoginForm) {
        return viewFn(route, prefix, data);
    };
}

export function routeEntry(viewFn: LoginPageView): core.RouteEntry<LoginForm> {
    return core.routeEntry(PAGE_ROUTE, authFetch, makeAuthView(viewFn));
}
